const ReTriggerSubmissionKeys = {
  TITLE: "title",
  MODULE: "module",
  MENU: "menu",
  FROM_SUBMITTED_DATETIME: "fromSubmittedDatetime",
  TO_SUBMITTED_DATETIME: "toSubmittedDatetime",
  FROM_STATUS: "fromStatus",
  TO_STATUS: "toStatus",
  FROM_MESSAGE_FUNCTION: "fromMessageFunction",
  TO_MESSAGE_FUNCTION: "toMessageFunction",
  TO_AMENDMENT_CODE: "toAmendmentCode",
  TO_MANIFEST_MESSAGE_FUNCTION: "toManifestMessageFunction",
  TO_MANIFEST_AMENDMENT_CODE: "toManifestAmendmentCode",
  UPS_MESSAGE: "upsMessage",
  USER_ID: "userId",
  SEARCH_BUTTON: "searchButton",
  SUBMIT_SELECTED_RECORD_BUTTON: "submitSelectedRecordButton",
  SUBMIT_ALL_BUTTON: "submitAllButton",
  CLEAR_BUTTON: "clearButton",
  TRIP_NUMBER: "tripNumber",
  SUBMITTED_DATE: "submittedDate",
  CURRENT_STATUS: "currentStatus",
  MESSAGE_FUNCTION: "messageFunction",
  USER_ID_INCLUDED_CHILD_PARTY: "userIdIncludedChildParty",
  SUBMIT_SUCCESS_MESSAGE: "submitSuccessMessage",
  SUBMIT_FAIL_MESSAGE: "submitFailMessage",
  CCN: "ccn",
  USER_ID_NOT_FOUND: "userIdNotFound",
  ValidationMessage:{
    ERROR_LATER_THAN_FROM_SUBMITTED_DATETIME: "validationMessage.errLaterThanFromSubmittedDatetime",
  }
}

export default ReTriggerSubmissionKeys
