import {
  components,
  constants,
  DataFlattener,
  useServices,
  useTranslation,
  useWhiteLabelServiceSettings
} from 'cng-web-lib'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import Namespace from 'src/constants/locale/Namespace'
import { getStatusMetadata } from 'src/common/NACommon'
import Table from 'src/components/aciacehighway/Table'
import TinyChip from 'src/components/aciacehighway/TinyChip'
import CngSection from 'src/components/cngcomponents/CngSection'
import ShipmentTable from './view/ShipmentTable'
import ConfirmDialog from '../../common/ConfirmDialog'
import pathMap from 'src/paths/pathMap'
import moment from 'moment-timezone'
import { Box, Card, Chip, Grid, Radio, Tooltip, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { format } from 'date-fns'
import TemplateSelectDialogWithOption from 'src/components/aciacehighway/TemplateSelectDialogWithOption'
import NoteFlagDialog from 'src/views/acehighway/manifest/NoteFlagDialog'
import CngBackdrop from "src/components/cngcomponents/CngBackDrop";
import { FileForUserGetPartyId, FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import EmailCoverSheetDialog from 'src/views/acehighway/manifest/EmailCoverSheetDialog'
import FaxCoverSheetDialog from 'src/views/acehighway/manifest/FaxCoverSheetDialog'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import sweetalert from 'sweetalert'
import ResponseLog from './view/ResponseLog'
import ChargeTypeDialog from '../../common/ChargeTypeDialog'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";

const {
  button: { CngButton },
  table: { useFetchCodeMaintenanceLookup },
  CngDialog,
  CngGridItem
} = components

const { filter: { BETWEEN, EQUAL, IN, LIKE } } = constants

function TablePage(props) {
  const { history, location, manifestId,  showNotification } = props
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest } = useServices()
  const tableRef = useRef(null)
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const { translate } = useTranslation([Namespace.ACE_HIGHWAY_SHIPMENT, Namespace.ACE_HIGHWAY_MANIFEST])
  const translatedTextsObject = makeTranslatedTextsObject()
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("ace-highway")

  const [lookups, setLookups] = useState(null)
  const [quickGlanceShipmentDialog, setQuickGlanceShipmentDialog] = useState({ open: false, manifest: null })
  const [quickGlanceResponseLogDialog, setQuickGlanceResponseLogDialog] = useState({ open: false, manifest: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, manifest: null })
  const [emailCoverSheetDialog, setEmailCoverSheetDialog] = useState({ open: false, data: null })
  const [faxCoverSheetDialog, setFaxCoverSheetDialog] = useState({ open: false, data: null })
  const [manifestTemplateDialog, setManifestTemplateDialog] = useState(false)
  const [noteFlagDialog, setNoteFlagDialog] = useState({ open: false, manifest: null })
  const [loading, setLoading] = useState(false)

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, items: [] })
  const [isSuperParty, setSuperParty] = useState(false);

  const [user, setUser] = useState([]);
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(() => {
    Promise.all([
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([billingChargeTypes, billingSuperParty]) => {
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )
  }, [])

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }
  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // Transport indicator
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACE_HW_TRANSPORT_INDICATOR'
          }
        ],
        undefined,
        'code'
      ),
      // First expected port
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [
            {
              field: 'set_indicator',
              operator: EQUAL,
              value: 'ACEHWY_POE'
            }
          ],
          customData: { codeMType: 'DocPortCode' }
        },
        (res) => {
          const result = {}

          res.content.forEach((item) => {
            result[item.code] = item.descriptionEn
          })

          return result
        }
      )
    ]).then(([status, transportIndicator, firstExpectedPort]) => {
      setLookups({ status, transportIndicator, firstExpectedPort })
    })
  }, [])

  function getDriverName(data) {
    var fullName = '';
    for (let d in data.partyInfo) {
      var row = data.partyInfo[d]
      if (row.partyType === 'VW') {
        fullName = row.firstName + ' ' + row.lastName
      }
    }
    return fullName;
  }

  function getShipmentCount(data) {
    var count = 0;
    for (let d in data.shipmentInfo) {
      count++;
    }
    return count;
  }

  function getShipmentCountStyle(data) {
    var green = true;
    for (let d in data.shipmentInfo) {
      var row = data.shipmentInfo[d];
      if (!row.brokerEntryNo && row.shipmentType != "IN-BOND" && row.shipmentType != "3299"
        && row.shipmentType != "3311" && row.shipmentType != "7523" && row.shipmentType != "SEC 321") {
        green = false;
      }
    }

    if (data.status == 1001 || data.status == 1005) {
      return getStatusMetadata(data.status);
    } else if (green) {
      return { color: '#008000' }
    }
    return { color: '#DF2901' }
  }

  function makeTranslatedTextsObject() {
    let manifestTitle = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TITLE
    )
    let id = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ID
    )
    let templateName = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TEMPLATE_NAME
    )
    let carrierScac = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CARRIER_SCAC
    )
    let tripNo = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TRIP_NO
    )
    let driver = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.DRIVER
    )
    let truck = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TRUCK
    )
    let trailer = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TRAILER
    )
    let shipmentCount = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SHIPMENT_COUNT
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CREATED_DATE
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.UPDATED_DATE
    )
    let submittedDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SUBMITTED_DATE
    )
    let arrivalDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.ARRIVAL_DATE
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.STATUS
    )
    let reviewStatus = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.REVIEW_STATUS
    )
    let transitIndicator = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.TRANSIT_INDICATOR
    )
    let firstExpectedPort = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.FIRST_EXPECTED_PORT
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.RESPONSE_DATE
    )
    let editSentRecords = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EDIT_SENT_RECORDS
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EDIT_BUTTON
    )
    let deleteDraftRecords = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.DELETE_DRAFT_RECORDS
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.DELETE_BUTTON
    )
    let cancelButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CANCEL_BUTTON
    )
    let cancelMultipleRecords = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CANCEL_MULTIPLE_RECORDS
    )
     let cloneButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.CLONE_BUTTON
    )
    let printManifestShipment = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PRINT_MANIFEST_SHIPMENT
    )
    let printCoverSheet = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PRINT_COVERSHEET
    )
    let emailCoverSheet = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.EMAIL_COVERSHEET
    )
    let faxCoverSheet = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.FAX_COVERSHEET
    )
    let printCoverSheetSummary = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PRINT_COVERSHEET_SUMMARY
    )
    let print7533 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PRINT_7533
    )
    let print7512 = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PRINT_7512
    )
    let printMultipleRecords = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.PRINT_MULTIPLE_RECORDS
    )
    let scn = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SCN
    )
    let saveButton = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.SAVE_BUTTON
    )
    let noteFlag = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.NOTE_FLAG
    )
    let noteContent = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.NOTE_CONTENT
    )
    let additionalShipment = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ADDITIONAL_SHIPMENT
    )
    return {
      manifestTitle,
      id,
      templateName,
      carrierScac,
      tripNo,
      driver,
      truck,
      trailer,
      shipmentCount,
      createdDate,
      updatedDate,
      submittedDate,
      arrivalDate,
      status,
      reviewStatus,
      transitIndicator,
      firstExpectedPort,
      responseDate,
      editSentRecords,
      editButton,
      deleteDraftRecords,
      deleteButton,
      cloneButton,
      cancelButton,
      cancelMultipleRecords,
      printManifestShipment,
      printCoverSheet,
      emailCoverSheet,
      faxCoverSheet,
      printCoverSheetSummary,
      print7533,
      print7512,
      printMultipleRecords,
      scn,
      saveButton,
      noteFlag,
      noteContent,
      additionalShipment,
      headers: {
        tripNo: translate(
          Namespace.ACE_HIGHWAY_MANIFEST,
          "manifestTable.tripNo"
        ),
        shipmentStatusSCN: translate(
          Namespace.ACE_HIGHWAY_MANIFEST,
          "manifestTable.shipmentStatusSCN"
        ),
        transitETA: translate(
          Namespace.ACE_HIGHWAY_MANIFEST,
          "manifestTable.transitETA"
        ),

      }
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  let columns = [
    {
      field: 'noteContentExist',
      title: translatedTextsObject.noteFlag,
      render: (data) => {
        let content = [
            <Tooltip key='more' title={data.reminderNote} placement='top'>
              <Chip
                clickable
                onClick={(event) => {
                  event.stopPropagation()
                  setNoteFlagDialog({ open: true, manifest: data })
                }}
              icon={<FontAwesomeIcon fixedWidth icon={['fas', 'flag']} style={data.noteContentExist ? { color: 'red' } : null} />}
                size='small'
                style={{ fontSize: 12 , backgroundColor:'transparent'}}
              />
            </Tooltip>
        ]
        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>{content}</Box>
        )
      }
    },
    {
      field: 'tripNo',
      sortKey: 'tripNo',
      title: translatedTextsObject.headers.tripNo
    },
    {
      field: 'arrivalDate',
      sortKey: 'arrivalDate',
      title: translatedTextsObject.arrivalDate,
      render: (data) => data.arrivalDate && moment(data.arrivalDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <>
            <Tooltip key='more' title='View response(s)' placement='top'>
              <Chip
                label={status.label}
                size='small'
                style={{
                  backgroundColor: status.color,
                  color: status.contrastColor
                }}
                clickable
                onClick={(event) => {
                  event.stopPropagation()
                  setQuickGlanceResponseLogDialog({ open: true, manifest: data })
                }}
              />
            </Tooltip>
            <Box mt={0.5}>
              <Typography color='textSecondary' style={{ fontSize: 12, lineHeight: 1.2 }}              >
                {data.responseDateTime && moment(data.responseDateTime).tz('Canada/Eastern').format('D MMM YYYY, HH:mm')}
              </Typography>
            </Box>
          </>
        )
      }
    },
    {
      title: translatedTextsObject.headers.shipmentStatusSCN,
      field: 'firstShipmentInfo',
      render: (data) => {
        let firstShipmentInfo = data.firstShipmentInfo

        if (!firstShipmentInfo) return

        let firstShipmentInfoSplit =  firstShipmentInfo.split("|")
        let firstShipmentInfoStatus = firstShipmentInfoSplit[0]
        let firstShipmentInfoSCN = firstShipmentInfoSplit[1]
        let statusIndicator = {
          backgroundColor: "#29A855",
          color: "#ffffff"
        };

        if (data.status == '1001' || data.portMismatch > 0 || data.rejectedShipment > 0) {
          statusIndicator.backgroundColor = "#DF2901";
        } else if (data.awaitingEntryNo > 0) {
          statusIndicator.backgroundColor = "#ffc400";
          statusIndicator.color = "#000000"
        }

        const status = getStatusMetadata(firstShipmentInfoStatus)
        if (status.label == 'Accepted') {
          status.color = statusIndicator.backgroundColor;
          status.contrastColor = statusIndicator.color;
        }

        let content = [
          <Chip
            key='first'
            label={[status.label, firstShipmentInfoSCN].join(' | ')}
            size='small'
            style={{
              color: status.contrastColor,
              backgroundColor: status.color,
              fontSize: 12
            }}
          />
        ]
        
        // Port Mismatch chip
        if (data.portMismatch) {
          content.push(
            <Chip key='first' label={"Port Mismatch"} size='small'
              style={{
                color: '#ffffff',
                backgroundColor: '#DF2901',
                fontSize: 12
              }}
            />
          )
        }

        function renderButton() {
          const otherShipmentInfo = data.noOfShipment - 1;

          return (
            <Tooltip key='more' title='Quick Glance at Shipment' placement='top'>
              <Chip
                label={otherShipmentInfo > 0 ? `${otherShipmentInfo} more` : 'view'}
                clickable
                onClick={(event) => {
                  event.stopPropagation()
                  setQuickGlanceShipmentDialog({ open: true, manifest: data })
                }}
                icon={<FontAwesomeIcon fixedWidth icon={['fal', 'eye']} />}
                size='small'
                style={{ fontSize: 12 }}
              />
            </Tooltip>
          )
        }

        return (
          <>
            <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
              {content}
            </Box>
            {(data.noOfShipment && data.noOfShipment > 0) && (
              <Box mt={0.5}>
                {renderButton()}
              </Box>
            )}
          </>
        )
      }
    },
    {
      field: "fullName",
      sortKey: 'fullName',
      title: translatedTextsObject.driver,
      //render: (data) => getDriverName(data)
    },
    {
      field: "conveyanceNo",
      sortKey: 'conveyanceNo',
      title: translatedTextsObject.truck,
    },
    {
      field: "equipmentNo",
      sortKey: 'equipmentNo',
      title: translatedTextsObject.trailer,
      //render: (data) => (undefined !== data.equipmentInfo[0] ? data.equipmentInfo[0].equipmentNo : '')
    },
    // {
    //   field: "shipmentCount",
    //   title: translatedTextsObject.shipmentCount,
    //   render: (data) => {
    //     const style = getShipmentCountStyle(data);

    //     return (
    //       <Typography variant='inherit' style={{ color: style.color }}>
    //         {getShipmentCount(data)}
    //       </Typography>
    //     )
    //   }
    // },
    {
      field: 'firstExpectedPort',
      sortKey: 'firstExpectedPort',
      title: translatedTextsObject.firstExpectedPort,
      render: (data) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {getLookupValue('firstExpectedPort', data.firstExpectedPort)}
            <TinyChip label={data.firstExpectedPort} variant='outlined' />
          </Typography>
        </Box>
      )
    },
    {
      field: 'createdDate',
      sortKey: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (data) => data.createdDate && moment(data.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    },
    {
      field: 'submittedDate',
      sortKey: 'submittedDate',
      title: translatedTextsObject.submittedDate,
      render: (data) => data.submittedDate && data.submittedDate !== '' && data.submittedDate !== 'Invalid date' && moment(data.submittedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
    }
  ]

  let challengerColumns = [
    {
      field: 'apiAutoSubmit',
      sortKey: 'reviewStatus',
      title: translatedTextsObject.reviewStatus,
      render: (data) => {
        if(data.apiAutoSubmit){
          const reviewStatus = getStatusMetadata(data.apiAutoSubmit)

          return (
            <>
              <Chip
                label={reviewStatus.label}
                size='small'
                style={{
                  backgroundColor: reviewStatus.color,
                  color: reviewStatus.contrastColor
                }}
              />
            </>
          )
        }
      }
    }
  ]

  if (whiteLabelServiceSettings && whiteLabelServiceSettings.length > 0
    && whiteLabelServiceSettings[0].custom.UI_MANIFEST_TABLE_REVIEW_STATUS === 'true') {
    columns = [...columns, ...challengerColumns]
  }
  
  const filters = [
    {
      label: translatedTextsObject.tripNo,
      type: 'textfield',
      name: 'tripNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.driver,
      type: 'textfield',
      name: 'fullName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.truck,
      type: 'textfield',
      name: 'conveyanceNo',
      operatorType: LIKE
    },
    {
      label: 'Follow-up Status',
      type: 'radio',
      name: 'firstShipmentInfo',
      operatorType: EQUAL,
      options: [
        { label: 'Pending Entry Number', value: 'yellow', filterValue: { value: 'yellow' } },
        { label: 'Port Mismatch', value: 'red', filterValue: { value: 'red' } }
      ]
    },
    {
      label: translatedTextsObject.trailer,
      type: 'textfield',
      name: 'equipmentNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.firstExpectedPort,
      type: 'textfield',
      name: 'firstExpectedPort',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.arrivalDate,
      type: 'daterange',
      name: 'arrivalDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.submittedDate,
      type: 'daterange',
      name: 'submittedDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          fullWidth: true,
          label: 'Preliminary',
          value: 'preliminary',
          filterValue: {
            value: '1009'
          }
        },
        {
          fullWidth: true,
          label: 'Status Notification',
          value: 'status_notification',
          filterValue: {
            value: '1006'
          }
        },
        {
          fullWidth: true,
          label: 'Cancellation Accepted',
          value: 'cancellation_accepted',
          filterValue: {
            value: '1003'
          }
        },
        {
          fullWidth: true,
          label: 'Cancellation Rejected',
          value: 'cancellation_rejected',
          filterValue: {
            value: '1004'
          }
        },
        {
          fullWidth: true,
          label: 'Modification Rejected',
          value: 'modification_rejected',
          filterValue: {
            value: '1007'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.reviewStatus,
      type: 'checkbox',
      name: 'apiAutoSubmit',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Pending Review',
          value: 'pending_review',
          filterValue: {
            value: 'R'
          }
        },
        {
          fullWidth: true,
          label: 'Review in Progress',
          value: 'review_in_progress',
          filterValue: {
            value: 'Y'
          }
        },
        {
          fullWidth: true,
          label: 'Reviewed',
          value: 'reviewed',
          filterValue: {
            value: 'G'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.transitIndicator,
      type: 'checkbox',
      name: 'transitIndicator',
      operatorType: LIKE,
      options: [
        {
          label: 'Importation',
          value: 'importation',
          filterValue: {
            value: 'importation'
          }
        },
        {
          label: 'Exportation',
          value: 'exportation',
          filterValue: {
            value: 'exportation'
          }
        },
        {
          label: 'Transit',
          value: 'transit',
          filterValue: {
            value: 'transit'
          }
        }
      ]
    }
  ]

  function handleCloneManifest(data) {
    createRecord.execute(
      AceHighwayManifestApiUrls.CLONE,
      data,
      (data) => {

        data.arrivalTime = moment(data.arrivalDate).tz("Canada/Eastern").format('HH:mm');
        data.arrivalDate = moment(data.arrivalDate).tz("Canada/Eastern").format('YYYY-MM-DD');

        const partyData = data.partyInfo.map(
          (partyInfo, index) => ({ ...partyInfo, _id: index + 1 })
        )
        data.partyInfo = partyData

        const equipmentData = data.equipmentInfo.map(
          (equipmentInfo, index) => ({ ...equipmentInfo, _id: index + 1 })
        )
        data.equipmentInfo = equipmentData

        const insuranceData = data.insuranceInfo.map(
          (insuranceInfo, index) => ({ ...insuranceInfo, _id: index + 1 })
        )
        data.insuranceInfo = insuranceData
        data.fromCloneOrTemplate = true
        history.push(pathMap.ACE_HIGHWAY_MANIFEST_ADD_VIEW, data)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDeleteManifest() {
    if (confirmDialog.manifest) {
      deleteRecord.execute(
        AceHighwayManifestApiUrls.DELETE,
        confirmDialog.manifest,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, manifest: null })
          setManifestTemplateDialog(false)
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  if(loading){
    return(
      <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
        <CngBackdrop loading={loading} />
      </CngGridItem>
    )
  }

  function preCancel(data) {
    if (isSuperParty || (null != user.corpid && undefined != user.corpid && user.corpid.length > 0 && user.corpid[0].serviceBureauFlag)) {
      setSubmitDialog({ open: true, items: data })
    } else {
      data.chargeAs = 'REGULAR'
      handleCancelManifest(data)
    }
  }

  function handleCancelManifest(data) {
    setLoading(true)
    const values = { ...data }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      values.fileForUserId = fileForUserDetails.fileForUserId;
      values.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      values.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    values.messageFunction = '3'
    values.messageIndicator = 'SINGLE'

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))
      }
    })

    instance
      .post(AceHighwayManifestApiUrls.SUBMIT, DataFlattener.unflatten(values))
      .then(
        (result) => {
          var returnData = result.data
          var errorMessages = returnData.errorMessages
          if (
            result.status == 200 &&
            returnData != null &&
            errorMessages == null
          ) {
            showNotification('success', 'Manifest cancelled.')
            tableRef.current.performRefresh()
            setLoading(false)
          } else {
            showNotification('error', errorMessages)
          }
        },
        (error) => {
          console.log(error)
        }
      )
  }

  function handlePrintManifestShipment(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      AceHighwayManifestApiUrls.PRINT_MANIFEST_SHIPMENT,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ACEHW_SHIPMENT_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"

        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function handlePrintCoverSheet(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      AceHighwayManifestApiUrls.PRINT_MANIFEST_COVERSHEET,
      data,
      (response) => {
        let blob = new Blob([response.data])

        let first = 'ACEHW_COVERSHEET_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"

        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function handlePrintCoverSheetSummary(data) {
    const config = { responseType: 'blob' }

    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      AceHighwayManifestApiUrls.PRINT_MANIFEST_COVERSHEET_SUMMARY,
      data,
      (response) => {
        let blob = new Blob([response.data])
        let first = 'ACEHW_COVERSHEET_SUMMARY_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      },
      (error) => console.error(error),
      undefined,
      config,
      null
    )
  }

  function handlePrint7533(data) {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    fetch(AceHighwayManifestApiUrls.PRINT_7533, {
      method: 'POST',
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('userToken')),
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      response.blob().then((blob) => {
        let first = 'ACEHW_7533_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      })
    })
  }

  function handlePrint7512(data) {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      data.fileForUserId = fileForUserDetails.fileForUserId;
      data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    //the print renamed from 7521 to 7512 but BE API still remained as same
    fetch(AceHighwayManifestApiUrls.PRINT_7521, {
      method: 'POST',
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('userToken')),
        'content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((response) => {
      response.blob().then((blob) => {
        let first = 'ACEHW_7512_'
        let curDate = format(new Date(), 'yyyyMMddHHmmss')
        let filename = first + curDate + ".pdf"
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.href = url
        a.download = filename
        a.click()
      })
    })
  }

  async function exportManifest(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      for (let i = 0; i < data.length; i++) {
        data[i].arrivalDate = null
        data[i].responseDate = null;
        data[i].submittedDate = null;
      }

      securedSendRequest.execute('POST', AceHighwayManifestApiUrls.EXPORT_MANIFEST, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'ACEHW_MANIFEST_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.zip'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACE_HIGHWAY_MANIFEST_ADD_VIEW)
            },
            label: 'Create manifest'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => setManifestTemplateDialog(true)
            },
            label: 'Manifest template'
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: AceHighwayManifestApiUrls.CUSTOM_EXPORT }}
        fetch={{ url: AceHighwayManifestApiUrls.CUSTOM_SEARCH }}
        fetchFilters={[
          { field: 'templateFlag', operator: EQUAL, value: '0' },
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
        if(rowData.status === '1008'){
            history.push(`${location.pathname}/view/${rowData.id}`)
        }else{
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
         }
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? translatedTextsObject.editSentRecords
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneManifest
          },
          {
            disabled: (rowData) =>
              rowData.status !== '1005' && rowData.status !== '1002' && rowData.status !== '1014',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setConfirmDialog({ open: true, manifest: rowData }),
            tooltip: (rowData) =>
              rowData.status !== '1005' && rowData.status !== '1002' && rowData.status !== '1014'
                ? translatedTextsObject.deleteDraftRecords
                : null
          },
          {
            disabled: (rowData) =>
              rowData.status !== '1000',
            label: translatedTextsObject.cancelButton,
            icon: <FontAwesomeIcon icon={['fal', 'ban']} />,
            onClick: preCancel,
            tooltip: (rowData) =>
                rowData.status !== '1000'
                ? translatedTextsObject.cancelMultipleRecords
                : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printManifestShipment,
            onClick: handlePrintManifestShipment
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printCoverSheet,
            onClick: handlePrintCoverSheet
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'envelope']} />,
            label: translatedTextsObject.emailCoverSheet,
            onClick: (rowData) => setEmailCoverSheetDialog({ open: true, data: rowData }),
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'fax']} />,
            label: translatedTextsObject.faxCoverSheet,
            onClick: (rowData) => setFaxCoverSheetDialog({ open: true, data: rowData }),
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printCoverSheetSummary,
            onClick: handlePrintCoverSheetSummary
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.print7533,
            onClick: handlePrint7533
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.print7512,
            onClick: handlePrint7512
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        tablePreference={{ module: 'ACEHWY', key: 'manifest_table_visible_column' }}
        selectActions={[
          {
            // disabled: (rows) => rows.length > 100,
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export Manifest(s)",
            onClick: (rows) => {
              if (rows.length > 100) {
                sweetalert("Maximum of 100 records per export.", "", "error");
              } else {
                sweetalert("Exporting Manifest in the background. The report will be downloaded automatically once ready.", "", "success");
                return exportManifest(rows);
              }
            },
            tooltip: (rows) => rows.length > 100 ? "Maximum of 100 records per export" : null

          }
        ]}
      />
      <QuickGlanceShipmentDialog
        getLookupValue={getLookupValue}
        manifest={quickGlanceShipmentDialog.manifest}
        onClose={() => setQuickGlanceShipmentDialog({ open: false, manifest: null })}
        open={quickGlanceShipmentDialog.open}
        translatedTextsObject={translatedTextsObject}
      />

      <QuickGlanceResponseLogDialog
        getLookupValue={getLookupValue}
        manifest={quickGlanceResponseLogDialog.manifest}
        onClose={() => setQuickGlanceResponseLogDialog({ open: false, manifest: null })}
        open={quickGlanceResponseLogDialog.open}
        translatedTextsObject={translatedTextsObject}
      />
      <TemplateSelectDialogWithOption
        manifestTemplateDialog={manifestTemplateDialog}
        setManifestTemplateDialog={setManifestTemplateDialog}
        setConfirmDialog={setConfirmDialog}
        showNotification={showNotification}
        translatedTextsObject={translatedTextsObject}
        isTable={true}
        location={location}
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(pathMap.ACE_HIGHWAY_MANIFEST+`/edit/${rowData.id}`),
            tooltip: () => 'edit'
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: 'Delete',
            onClick: (rowData) =>
              setConfirmDialog({ open: true, manifest: rowData }),
            tooltip: () => 'delete'
          }
        ]}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, manifest: null })}
        confirmDialog={handleDeleteManifest}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <NoteFlagDialog
        openDialog={noteFlagDialog}
        manifestId={manifestId}
        onClose={() => setNoteFlagDialog({ open: false, manifest: null })}
        showNotification={showNotification}
        tableRef={tableRef}
      />
      <EmailCoverSheetDialog
        openDialog={emailCoverSheetDialog}
        onClose={() => setEmailCoverSheetDialog({ open: false, data: null })}
        showNotification={showNotification}
      />
      <FaxCoverSheetDialog
        openDialog={faxCoverSheetDialog}
        onClose={() => setFaxCoverSheetDialog({ open: false, data: null })}
        showNotification={showNotification}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, items: [] })}
        onSubmit={(data) => {
          handleCancelManifest(data)
        }}
        open={submitDialog.open}
        items={submitDialog.items}
      />
    </>
  )
}

export default TablePage

function QuickGlanceShipmentDialog(props) {
  const { getLookupValue, manifest, onClose, open, translatedTextsObject } = props
  const { securedSendRequest } = useServices()
  const theme = useTheme()
  const [statusList, setStatusList] = useState([])
  const [redirectUrl, setRedirectUrl] = useState()

  const redirectData = {
    manifestData: {...manifest},
    step: "2"
  }

  useEffect(() => {

    //reset status
    setStatusList([])
    if(manifest!= null && manifest!=undefined && open){

      if(manifest.status!=null && manifest.status!=undefined && manifest.status=="1008"){
        setRedirectUrl("/ace-highway/manifest/view/"+manifest.id)
      }else{
        setRedirectUrl("/ace-highway/manifest/edit/"+manifest.id)
      }
      

      const formData = new FormData();
      formData.append('manifestId', manifest.id);

      let config = {}

      securedSendRequest.execute(
        'POST',
        AceHighwayShipmentApiUrls.GET_COUNT_BY_STATUS,
        formData,
        (response) => {
          console.log(response)
          let data = response.data
          if(data!=null && data!=undefined && data.length>0){
              var result = data.reduce(function(map, obj) {
                map[obj.STATUS] = obj.TOTAL;
                return map;
            }, {});
              setStatusList(data)
          }
    },
        (error) => console.error(error),
        undefined,
        config,
        null
  )
    }
  },[open]);

  let content = manifest ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <Box px={2} py={1}>
            <Grid container justify='space-between' spacing={2}>
              <Grid item xs={12} sm='auto'>
                <Typography variant='h6' style={{ fontWeight: 700 }}>
                  Total No. of Shipment In
                </Typography>
              </Grid>
              <Grid item xs={12} sm='auto'>
                <Typography variant='body2'>
                  <Typography
                    color='textSecondary'
                    component='span'
                    variant='inherit'
                    style={{ fontWeight: 700 }}
                  >
                    Carrier SCAC &amp; Trip Number:&nbsp;
                  </Typography>
                  <Typography component='span' variant='inherit' style={{ fontWeight: 700 }}>
                    {manifest.tripNo}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box px={2} py={1} style={{ backgroundColor: theme.palette.background.sectionOddBg }}>
            <Grid container flexWrap='wrap' spacing={2}>
              {statusList.map((data) => {
                const statusData = getStatusMetadata(data.STATUS)
                return (
                  <Grid item xs='auto'>
                    <Typography variant='body2' style={{ fontWeight: 700 }}>
                      {statusData.label}&nbsp;
                      <Typography
                        color='primary'
                        component='span'
                        variant='inherit'
                      >
                        {data.TOTAL}
                      </Typography>
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <CngSection
          title={
            <Box display='flex' alignItems='baseline' style={{ gap: 4 }}>
              <Typography style={{ fontWeight: 600 }}>
                Shipment
              </Typography>
              <Typography color='textSecondary' variant='caption'>
                {/*`/ ${manifest.shipmentInfo.length} Entries`*/}
              </Typography>
            </Box>
          }
        >
          <ShipmentTable
            disableView
            getLookupValue={getLookupValue}
            manifestId={manifest.id}
            manifestPort={manifest.firstExpectedPort}
            noOfShipment={manifest.noOfShipment}
            redirectUrl={redirectUrl}
            redirectData={redirectData}
            additionalButtonLabel={translatedTextsObject.additionalShipment}
          />
        </CngSection>
      </Grid>
    </Grid>
  ) : null

  return (
    <CngDialog
      dialogContent={content}
      dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
      dialogTitle='Quick Glance at Shipment'
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      fullWidth
      maxWidth='lg'
      onClose={onClose}
      open={open}
      shouldShowCloseButton
    />
  )
}

function QuickGlanceResponseLogDialog(props) {
  const { getLookupValue, manifest, onClose, open, translatedTextsObject } = props
    const { securedSendRequest } = useServices()
    const theme = useTheme()
    const [statusList, setStatusList] = useState([])
    const [redirectUrl, setRedirectUrl] = useState()
  
    const redirectData = {
      manifestData: {...manifest},
      step: "2"
    }
  
  let content = manifest ? (
      <Grid item xs={12}>
            <ResponseLog
              disableView
              getLookupValue={getLookupValue}
              manifestId={manifest.id}
              manifestPort={manifest.firstExpectedPort}
             noOfShipment={manifest.noOfShipment}
             redirectUrl={redirectUrl}
             redirectData={redirectData}
             additionalButtonLabel={translatedTextsObject.additionalShipment}
            />   
        </Grid>
    ) : null
      return (
      <CngDialog
        dialogContent={content}
        dialogAction={<CngButton onClick={onClose}>OK</CngButton>}
        dialogTitle='Response log '
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        fullWidth
        maxWidth='lg'
        onClose={onClose}
        open={open}
        shouldShowCloseButton
      />
    )
  
}