import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fab, Menu, MenuItem, Zoom, makeStyles, useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    top: theme.spacing(15.25),
    right: theme.spacing(5),
    '& > .MuiFab-label': {
      textTransform: 'none',
      '& > *': { padding: theme.spacing(1) }
    }
  }
}))

/**
 * 
 * @param {array} pages list of objects containing the page `label` and the list of `sections` in the format 
 *                      `{label: <string>, id: <string>}`, where `id` is the HTML element id for the section header.
 * @param {int} pageNum current page number (zero-based) corresponding to the order of items in `pages`.
 * @param {string} label Label to use on the jumper button.
 * @returns 
 */
function SectionJumper({pages = [], pageNum=0, label = 'Jump to section', ...props}) {  
  const theme = useTheme()
  const classes = useStyles()
  const [menuOptions, setMenuOptions] = useState([])
  const [anchorEl, setAnchorEl] = useState()

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }

  const handleMenu = (e, sections) => {
    setMenuOptions(sections)
    setAnchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setMenuOptions([])
  }

  const handleJump = id => {
    const element = document.getElementById(id)
    if (element)
      element.scrollIntoView({ behavior: 'smooth' })
    else
      alert('no id found')
    handleClose()
  }

  return <>
    {pages.map((page, p) => page?.sections && <Zoom
      key={`page-${p}`}
      in={pageNum === p}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${pageNum === p ? transitionDuration.exit : 0}ms`,
      }}
      unmountOnExit
    >
      <Fab variant='extended' color="primary" size="medium"
        aria-label={`${label} in ${page.label}`}
        aria-controls='section-jump-menu'
        aria-haspopup="true"
        onClick={e => handleMenu(e, page.sections)}
        className={classes.fab}
        {...props}
      >
        <FontAwesomeIcon icon="arrow-right-long-to-line" fixedWidth />
        {label}
      </Fab>
    </Zoom>)}
    <Menu
      id="section-jump-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {menuOptions.map(({label, id}, s) =>
        <MenuItem onClick={() => handleJump(id)} key={s}>
          {label}
        </MenuItem>
      )}
    </Menu>
  </>
}

export default SectionJumper