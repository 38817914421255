import {
  ViewPage,
} from 'src/views/retriggersubmission'
import pathMap from 'src/paths/pathMap'

class ReTriggerSubmissionRoute {
  getConfigurations() {
    return [
      {
        exact: true,
        path: pathMap.RE_TRIGGER_SUBMISSION_LIST_VIEW,
        component: ViewPage
      }
    ]
  }
}

export default ReTriggerSubmissionRoute
