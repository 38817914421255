import {
  Box,
  Card,
  Checkbox,
  CircularProgress,
  FormLabel,
  Grid,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  MenuList,
  Table as MuiTable,
  Paper,
  Popover,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
  withStyles
} from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { components, useServices } from 'cng-web-lib'

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TablePagination from './TablePagination'
import PropTypes from 'prop-types'
import _, { join } from 'lodash'
import moment from 'moment'
import HelperText from '../../views/common/HelperText'
import clsx from 'clsx'

import { getUIPreference, getUIPreferenceToServerObject } from 'src/common/UIPreference.js'
import UserProfileApiUrls from 'src/apiUrls/UserProfileApiUrls'

const DEFAULT_POPOVER_PROPS = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
  transformOrigin: { vertical: 'top', horizontal: 'right' }
}

const {
  button: { CngButton, CngIconButton },
  form: {
    field: { CngCheckboxField, CngDateField, CngRadioGroupField, CngTextField }
  },
  CngGridItem
} = components

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'unset',
    position: 'relative'
  },
  wrapper: {
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  bottomScrollBar: {
    position: 'sticky',
    bottom: 0
  },
  table: {
    marginTop: 0,
    minWidth: 900,
    '& .MuiTableHead-root': {
      backgroundColor: theme.palette.background.sectionOddBg,
      '& .MuiTableRow-head:not(.table-filter-row)': {
        '& .MuiTableCell-head': {
          fontSize: 12,
          fontWeight: 700,
          lineHeight: 1.2,
          padding: '12px 16px',
          textTransform: 'uppercase',
          '& .MuiTypography-root': {
            cursor: 'default'
          }
        }
      }
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root': {
        '&.Mui-selected': {
          backgroundColor: theme.palette.action.selected
        },
        '& .MuiTableCell-root': {
          borderBottom:
            theme.palette.mode === 'dark'
              ? '1px solid rgba(255, 255, 255, 0.2)'
              : '1px solid rgba(224, 224, 224, 1)',
          padding: (props) => (props.compact ? '8px 16px' : '12px 16px'),
          fontSize: theme.typography.body2.fontSize
        }
      }
    }
  },
  loader: {
    alignItems: 'center',
    backgroundColor: `rgba(255, 255, 255, 0.8)`,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  headerSortSelect: {
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: 700,
      padding: '4px 24px 4px 0'
    }
  },
  checkbox: {
    '& .MuiFormControlLabel-root': {
      marginLeft: 8,
      '& .MuiCheckbox-root': {
        padding: 8
      }
    }
  }
}))

function transformColumnKey(str) {
  if (!str) {
    return str
  }

  return str
    .replace(/[^a-zA-Z ]/g, '')
    .replace(/\s/g, '')
    .toLowerCase()
}

function Table(props) {
  const {
    actions,
    checkboxSelection = false,
    columns = [],
    compact,
    customRowActions = [],
    exportData,
    customExportData,
    fetch,
    fetchFilters = [],
    filters = [],
    helperText,
    onPostFetch,
    onRowClick,
    persistSettings = false,
    sessionSettingConst = window.location.pathname + "_table_filter",
    rowActions = [],
    tablePreference = { module: null, key: null },
    selectActions = [],
    onSelectRows,
    showNotification,
    sortConfig = { type: 'column', defaultDirection: 'ASC' },
    tableRef,
    variant = 'elevation'
  } = props

  const { exportRecords, fetchPageableRecords, securedSendRequest } = useServices()
  const theme = useTheme()
  const classes = useStyles({ compact })
  const history = useHistory()

  // Code block to retrieve table settings from session
  const sessionSettingJson = sessionStorage.getItem(sessionSettingConst)
  const sessionSetting = sessionSettingJson ? JSON.parse(sessionSettingJson) : null
  const useSessionSetting = () => {
    // Only use table settings from session if available, action is not REPLACE (from menu) and provided state of fecthAll is invalid or false
    const { action, location: { state } } = history
    const fetchAll = state?.fetchAll
    return persistSettings && !fetchAll && action && sessionSetting && (action == 'PUSH' || action == 'POP')
  }

  const transformFilterData = useCallback((data) => {
    const result = []

    Object.keys(data).forEach((key) => {
      const currentFilter = filters.find((filter) => filter.name === key)

      if (currentFilter) {
        switch (currentFilter.type) {
          case 'radio': {
            if (data[key] === 'all') return

            const filterOption = currentFilter.options.find(
              (option) => option.value === data[key]
            )

            if (filterOption) {
              result.push({
                field: currentFilter.name,
                operator: currentFilter.operatorType,
                ...filterOption.filterValue
              })
            }
            break
          }

          case 'checkbox': {
            if (data[key].length === 0) return

            result.push({
              field: currentFilter.name,
              operator: currentFilter.operatorType,
              value: data[key] //Values example: ['draft', 'sent', 'accepted']
                .map((value) => {
                  const filterOption = currentFilter.options.find(
                    (option) => option.value === value
                  )

                  // Map with option's filterValue
                  return filterOption.filterValue.value
                })
                .join('|') //Example: '1005|1008|1000'
            })
            break
          }

          case 'daterange': {
            if (!data[key].startDate || !data[key].endDate) return

            result.push({
              field: currentFilter.name,
              operator: currentFilter.operatorType,
              type: 'date',
              value: {
                startDate: moment(data[key].startDate, 'YYYY-MM-DD').format(),
                endDate: moment(data[key].endDate, 'YYYY-MM-DD').format()
              }
            })
            break
          }

          case 'select':
          case 'textfield': {
            if (data[key].length === 0) return

            result.push({
              field: currentFilter.name,
              operator: currentFilter.operatorType,
              value: data[key]
            })
            break
          }

          default:
            throw new Error('Invalid filter field type.')
        }
      }
    })

    return result
  }, [filters])

  // Inidcator for first fetch to skip saving table settings to session on first load
  const [data, setData] = useState({
    content: [],
    totalElements: 0,
    totalPages: 0
  })
  const [page, setPage] = useState(useSessionSetting() ? sessionSetting.page : 0)
  const [itemsPerPage, setItemsPerPage] = useState(useSessionSetting() ? sessionSetting.itemsPerPage : 10)
  const [filterValues, setFilterValues] = useState(useSessionSetting() ? sessionSetting.filterValues : null)
  const [tableFilters, setTableFilters] = useState(useSessionSetting() && sessionSetting.filterValues ? transformFilterData(sessionSetting.filterValues) : [])
  const [tableSorts, setTableSorts] = useState(useSessionSetting() ? sessionSetting.tableSorts : [
    {
      field: sortConfig?.defaultField || '',
      direction: sortConfig?.defaultDirection || 'ASC'
    }
  ])

  const [isFetching, setIsFetching] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [rowActionsPopover, setRowActionsPopover] = useState({
    anchorEl: null,
    rowData: null
  })
  const [tableColsPopoverAnchorEl, setTableColsPopoverAnchorEl] = useState(null)
  const [exportPopoverAnchorEl, setExportPopoverAnchorEl] = useState(null)
  const [tableColumns, setTableColumns] = useState(
    columns.map((column) => transformColumnKey(column.title))
  )
  const [refreshCount, setRefreshCount] = useState(0)
  const [isResetting, setIsResetting] = useState(false)

  //Clear state to use session table settings if navigate back using browser navigation
  window.history.replaceState({}, document.title)

  const tableContentRef = useRef(null)
  const topScrollBarRef = useRef(null)
  const bottomScrollBarRef = useRef(null)
  const tableContentWrapperRef = useRef(null)

  const hasActionColumn = !_.isEmpty(customRowActions) || !_.isEmpty(rowActions)
  const currentPageSelectedRows = selectedRows.filter((selectedRow) =>
    data.content.map((rowData) => rowData.id).includes(selectedRow.id)
  )

  useEffect(() => {
    // Stop second fetch action after filter fields are cleared
    if (isResetting) {
      setIsResetting(false)
    }

    setIsFetching(true)

    fetchPageableRecords.execute(fetch.url,
      {
        page: page,
        pageSize: itemsPerPage,
        filters: [...fetchFilters, ...tableFilters],
        sorts: tableSorts,
        ...(fetch.customData && { customData: fetch.customData })
      },
      (result) => {
        const { content, totalElements, totalPages } = result

        let data = [...content]
        if (onPostFetch) {
          data = onPostFetch(data)
        }
        setData({ content: data, totalElements, totalPages })
      },
      (error) => console.log(error),
      () => { setIsFetching(false) }
    )
  }, [itemsPerPage, page, refreshCount, tableFilters, tableSorts])

  useEffect(() => {
    if (persistSettings && (filterValues || page || itemsPerPage || tableSorts)) {
      const sessionSetting = { page, filterValues, itemsPerPage, tableSorts }
      sessionStorage.setItem(sessionSettingConst, JSON.stringify(sessionSetting))
    }
  }, [filterValues, page, itemsPerPage, tableSorts])

  useEffect(() => {
    if (onSelectRows) {
      onSelectRows(selectedRows);
    }
  }, [selectedRows])

  const [visibleColumns, setVisibleColumns] = useState([])
  useEffect(() => {
    if (tablePreference.module && tablePreference.key) {
      const columnsPref = getUIPreference(tablePreference.module, tablePreference.key)
      if (columnsPref.length > 0) {
        setVisibleColumns(columnsPref)
        setTableColumns(columnsPref)
      }
    }
  }, [])

  function resetTableSettings() {
    setIsResetting(true)

    setPage(0)
    setItemsPerPage(10)
    setFilterValues(null)
    setTableFilters([])
    setTableSorts([
      {
        field: sortConfig?.defaultField || '',
        direction: sortConfig?.defaultDirection || 'ASC'
      }
    ])
  }

  function handleItemsPerPageChange(event) {
    setPage(0)
    setItemsPerPage(parseInt(event.target.value))
  }

  function handleColumnSortChange(sortKey) {
    setTableSorts((prev) => [
      {
        field: sortKey,
        direction: _.isEmpty(prev)
          ? 'ASC'
          : prev[0].direction === 'ASC'
          ? 'DESC'
          : 'ASC'
      }
    ])
  }

  function handleHeaderSortChange(field, value) {
    const currentSort = [...tableSorts][0]

    setTableSorts([{ ...currentSort, [field]: value }])
  }

  const handleSelectAllRows = (event) => {
    // Selects rows from other table page
    const otherPageSelectedRows = selectedRows.filter(
      (selectedRow) =>
        !data.content.map((rowData) => rowData.id).includes(selectedRow.id)
    )

    if (event.target.checked) {
      setSelectedRows([...otherPageSelectedRows, ...data.content])
    } else {
      setSelectedRows(otherPageSelectedRows)
    }
  }

  function handleSelectRow(rowData) {
    const clonedSelectedRows = [...selectedRows]
    const index = clonedSelectedRows.findIndex(
      (selectedRow) => selectedRow.id === rowData.id
    )

    if (index !== -1) {
      clonedSelectedRows.splice(index, 1)
    } else {
      clonedSelectedRows.push(rowData)
    }

    setSelectedRows(clonedSelectedRows)
  }

  function handleExportData(type) {
    setIsFetching(true)

    exportRecords.execute(
      exportData.url,
      {
        filters: [...fetchFilters, ...tableFilters],
        sorts: tableSorts,
        columns: columns.map((column) => ({
          field: column.field,
          title: column.title
        })),
        fileType: type,
        fileName: 'data.' + type.toLowerCase()
      },
      (data, headers) => {
        const customMessage = headers['x-NGBF-Custom-Message'.toLowerCase()]
        if (customMessage) {
          showNotification('success', customMessage)
        }
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Something went wrong when exporting data.')
      },
      () => {
        setIsFetching(false)
      }
    )
  }

  function handleCustomExportData(type) {
    console.log("Custom Export CSV for MEDPID")
    setIsFetching(true)
    console.log("data: " + JSON.stringify(customExportData))
    let module = ""
    if (customExportData.url.includes("conveyance")) {
      module = "Conveyance"
    } else if (customExportData.url.includes("crew")) {
      module = "Crew"
    } else if (customExportData.url.includes("equipment")) {
      module = "Equipment"
    }
    exportRecords.execute(
      customExportData.url,
      {
        filters: [...fetchFilters, ...tableFilters],
        sorts: tableSorts,
        columns: {
          accId: customExportData.accId,
          partyId: customExportData.partyId
        },
        fileName: module + ' Download.' + type.toLowerCase()
      },
      (data, headers) => {
        const customMessage = headers['x-NGBF-Custom-Message'.toLowerCase()]
        if (customMessage) {
          showNotification('success', customMessage)
        }
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Something went wrong when exporting data.')
      },
      () => {
        setIsFetching(false)
      }
    )
  }

  const isColumnShown = useCallback(
    (columnKey) => {
      return tableColumns.includes(transformColumnKey(columnKey))
    },
    [tableColumns]
  )

  function performRefresh() {
    setRefreshCount((prev) => prev + 1)
    setSelectedRows([])
  }

  /**
   * Updates table data by pointing with an indicator
   * @param {Object|Object[]} payload Value used to update table data. Data type must be array or object.
   * @param {Object} config
   */
  function updateData(payload, config = { indicator: 'id' }) {
    const { indicator } = config
    const clonedData = [...data.content]
    const clonedSelectedRows = [...selectedRows]

    function replaceData(value) {
      const dataIndex = _.findIndex(clonedData, { [indicator]: value[indicator] })
      const selectedRowIndex = _.findIndex(clonedSelectedRows, { [indicator]: value[indicator] })

      clonedData.splice(dataIndex, 1, value)
      
      if (selectedRowIndex !== -1) {
        clonedSelectedRows.splice(selectedRowIndex, 1, value)
      }
    }

    if (Array.isArray(payload)) {
      payload.forEach((value) => replaceData(value))
    } else {
      replaceData(payload)
    }

    setData((prev) => ({ ...prev, content: clonedData }))
    setSelectedRows(clonedSelectedRows)
  }

  async function handleSaveTablePreference(visibleColumns, setSubmitting) {
    const data = getUIPreferenceToServerObject(tablePreference.module, tablePreference.key, visibleColumns)

    try {
      securedSendRequest.execute('POST', UserProfileApiUrls.POST, data,
        (response) => {
          sessionStorage.setItem('uiPreference', JSON.stringify(response.data.uiPreference))
          showNotification('success', 'Table Preference Saved.')
        },
        (error) => console.log('error', error),
        () => {
          setSubmitting(false)
          setTableColsPopoverAnchorEl(false)
        }
      )
    } catch (error) {
      showNotification('error', error.errors)
      setSubmitting(false)
    }
  }

  if (tableRef) {
    tableRef.current = {
      performRefresh,
      selectedRows,
      updateData,
      setLoading: (value) => setIsFetching(value)
    }
  }

  return (
    <>
      <Card className={clsx(classes.root, 'new-ui-table-component')} variant={variant}>
        <Grid container>
          {helperText &&
            <Box paddingLeft={2} paddingTop={2}>
              <HelperText helperText={helperText} />
            </Box>
          }
          {sortConfig.type === 'header' && (
            <Grid item xs={12}>
              <Box padding={2}>
                <Grid container justify='flex-end' spacing={2}>
                  <Grid item xs='auto'>
                    <Grid alignItems='center' container spacing={2}>
                      <Grid item xs='auto'>
                        <FormLabel
                          htmlFor='sort-by'
                          style={{ textTransform: 'uppercase', fontSize: 12 }}
                        >
                          Sort By:
                        </FormLabel>
                      </Grid>
                      <Grid item xs='auto'>
                        <TextField
                          className={classes.headerSortSelect}
                          id='sort-by'
                          hiddenLabel
                          select
                          value={tableSorts[0].field}
                          InputProps={{ disableUnderline: true }}
                          size='small'
                          onChange={(e) =>
                            handleHeaderSortChange('field', e.target.value)
                          }
                        >
                          {columns
                            .filter((column) => column.sortKey)
                            .map((column) => (
                              <MenuItem
                                key={column.sortKey}
                                value={column.sortKey}
                              >
                                {column.title}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs='auto'>
                    <Grid alignItems='center' container spacing={2}>
                      <Grid item xs='auto'>
                        <FormLabel
                          htmlFor='sort-ordering'
                          style={{ textTransform: 'uppercase', fontSize: 12 }}
                        >
                          Order By:
                        </FormLabel>
                      </Grid>
                      <Grid item xs='auto'>
                        <TextField
                          className={classes.headerSortSelect}
                          id='sort-ordering'
                          hiddenLabel
                          select
                          value={tableSorts[0].direction}
                          InputProps={{ disableUnderline: true }}
                          size='small'
                          onChange={(e) =>
                            handleHeaderSortChange('direction', e.target.value)
                          }
                        >
                          <MenuItem value='ASC'>Ascending</MenuItem>
                          <MenuItem value='DESC'>Descending</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box padding={2}>
              <Grid alignItems='center' container spacing={2}>
                <Grid item xs={12} sm='auto'>
                  {checkboxSelection && selectedRows.length > 0 && (
                    <Typography color='primary' variant='body2'>
                      {`${selectedRows.length} items/s selected`}
                    </Typography>
                  )}
                  {data.totalElements > 0 && (
                    <Typography variant='caption'>
                      {page * itemsPerPage + 1}-
                      {Math.min(
                        page * itemsPerPage + itemsPerPage,
                        data.totalElements
                      )}
                      &nbsp;of&nbsp;{data.totalElements}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm>
                  <Grid container alignItems='center' justify='flex-end' spacing={2}>
                    {checkboxSelection && selectedRows.length > 0 ? (
                      selectActions.map((selectAction, index) => {
                        const shouldHide = selectAction.hide ? selectAction.hide(selectedRows) : false
                        const SelectActionItem = () => (
                          <CngButton
                            disabled={selectAction.disabled ? selectAction.disabled(selectedRows) : false}
                            onClick={() => selectAction.onClick(selectedRows)}
                            startIcon={selectAction.icon}
                            {...selectAction.buttonProps}
                          >
                            {selectAction.label}
                          </CngButton>
                        )

                        return (
                          !shouldHide && (
                            <Grid key={index} item xs='auto'>
                              {selectAction.tooltip &&
                              selectAction.tooltip(selectedRows) !== null ? (
                                <Tooltip
                                  placement='top'
                                  title={selectAction.tooltip(selectedRows)}
                                >
                                  <span>
                                    <SelectActionItem />
                                  </span>
                                </Tooltip>
                              ) : (
                                <SelectActionItem />
                              )}
                            </Grid>
                          )
                        )
                      })
                    ) : (
                      <>
                          {persistSettings && 
                            <Grid item xs='auto'>
                            <Tooltip title="Clear Filter" placement="top">
                              <Box>
                                <CngIconButton size='small' type='outlined' icon={['fal', 'filter-slash']} onClick={resetTableSettings} />
                              </Box>
                            </Tooltip>
                            </Grid>
                          }
                          <Grid item xs='auto'>
                            <Tooltip title="Refresh" placement="top">
                              <Box>
                                <CngIconButton size='small' type='outlined' icon={['fal', 'refresh']} onClick={performRefresh} />
                              </Box>
                            </Tooltip>
                        </Grid>
                        {exportData && (
                          <Grid item xs='auto'>
                              <Tooltip title="Export" placement="top">
                                <Box>
                                <CngIconButton size='small' type='outlined' icon={['fal', 'file-export']}
                                  onClick={(event) => setExportPopoverAnchorEl(event.currentTarget)}
                              />
                                </Box>
                              </Tooltip>
                          </Grid>
                        )}
                          {customExportData && (
                            <Grid item xs='auto'>
                              <Tooltip title="Custom Export" placement="top">
                                <Box>
                                  <CngIconButton size='small' type='outlined' icon={['fal', 'file-export']}
                                    onClick={(event) => setExportPopoverAnchorEl(event.currentTarget)}
                                  />
                                </Box>
                              </Tooltip>
                            </Grid>
                          )}
                          <Grid item xs='auto'>
                            <Tooltip title="Configure View" placement="top">
                              <Box>
                                  <CngIconButton size='small' type='outlined' icon={['fal', 'columns']}
                                    onClick={(event) => setTableColsPopoverAnchorEl(event.currentTarget)}
                                />
                              </Box>
                            </Tooltip>
                        </Grid>
                        {!_.isEmpty(actions) && (
                          <Grid item xs='auto'>
                            <Grid container justify='flex-end' spacing={1}>
                              {actions.map((action, index) => (
                                <Grid key={index} item xs='auto'>
                                  <CngButton {...action.buttonProps}>
                                    {action.label}
                                  </CngButton>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box position='relative'>
        <ScrollBar
          tableContentRef={tableContentRef}
          onScroll={(event) => {
            const { scrollLeft } = event.target
        
            bottomScrollBarRef.current.scrollLeft = scrollLeft
            tableContentWrapperRef.current.scrollLeft = scrollLeft
          }}
          ref={topScrollBarRef}
        />
        <div className={classes.wrapper} ref={tableContentWrapperRef}>
          <MuiTable ref={tableContentRef} className={clsx(classes.table, 'new-ui-table-component__table')}>
            <TableHead>
              <TableRow>
                {checkboxSelection && (
                  <TableCell padding='checkbox'>
                    <Checkbox
                      disabled={data.content.length === 0}
                      indeterminate={
                        selectedRows.length > 0 &&
                        currentPageSelectedRows.length < data.content.length
                      }
                      checked={
                        data.content.length > 0 &&
                        currentPageSelectedRows.length === data.content.length
                      }
                      onChange={handleSelectAllRows}
                      size='small'
                    />
                  </TableCell>
                )}
                {columns.map((column, index) => {
                  const isColumnSorted =
                    !_.isEmpty(tableSorts) &&
                    sortConfig.type === 'column' &&
                    column.sortKey === tableSorts[0].field

                  return (
                    isColumnShown(column.title) && (
                      <TableCell
                        key={index}
                        {...(column.sortKey &&
                          sortConfig.type === 'column' && {
                            onClick: () =>
                              handleColumnSortChange(column.sortKey)
                          })}
                        {...column?.tableHeadCellProps}
                      >
                        <Box
                          alignItems='center'
                          display='flex'
                          flexDirection='row'
                          style={{ gap: 8 }}
                        >
                          {isColumnSorted && (
                            <FontAwesomeIcon
                              color={theme.palette.primary.main}
                              icon={[
                                'fal',
                                tableSorts[0].direction === 'ASC'
                                  ? 'caret-up'
                                  : 'caret-down'
                              ]}
                            />
                          )}
                          <Typography
                            color={isColumnSorted ? 'primary' : 'initial'}
                            variant='inherit'
                          >
                            {column.title}
                          </Typography>
                        </Box>
                      </TableCell>
                    )
                  )
                })}
                {hasActionColumn && <TableCell align='right'>Action</TableCell>}
              </TableRow>
              {!_.isEmpty(filters) && (
                <TableRow className='table-filter-row'>
                  {checkboxSelection && <TableCell />}
                  <TableFilterRow
                      columns={columns}
                      filters={filters}
                      filterValues={filterValues}
                      onChangeFilter={(filterData) => {
                        setTableFilters(filterData ? transformFilterData(filterData) : [])
                        setFilterValues(filterData)
                        setPage(0)
                      }}
                      tableColumns={tableColumns}
                      isResetting={isResetting}
                  />
                  {hasActionColumn && <TableCell />}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {data.content.length > 0 ? (
                data.content.map((datum, rowIndex) => {
                  const isSelected = selectedRows.findIndex((selectedRow) => selectedRow.id === datum.id) !== -1
                  return (
                    <TableRow
                      key={datum.id || rowIndex}
                      selected={isSelected}
                      {...(onRowClick && {
                        hover: true,
                        onClick: () => onRowClick(datum),
                        style: { cursor: 'pointer' }
                      })}
                    >
                      {checkboxSelection && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            onClick={(event) => {
                              event.stopPropagation()
                              handleSelectRow(datum)
                            }}
                            checked={isSelected}
                            size='small'
                          />
                        </TableCell>
                      )}
                      {columns.map(
                        (column, colIndex) =>
                          isColumnShown(column.title) && (
                            <TableCell
                              key={colIndex}
                              {...column?.tableBodyCellProps}
                            >
                              {column.render
                                ? column.render(datum)
                                : "Sno" === column.title ?
                                  page * itemsPerPage + rowIndex + 1 : _.get(datum, column.field)}
                            </TableCell>
                          )
                      )}
                      {hasActionColumn && (
                        <TableCell align='right'>
                          <Grid container justify='flex-end' spacing={1} wrap='nowrap'>
                            {customRowActions.map((action, index) => {
                              const ActionItem = () => (
                                <CngIconButton
                                  disabled={
                                    action.disabled
                                      ? action.disabled(datum)
                                      : false
                                  }
                                  icon={action.icon}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    action.onClick(datum)
                                  }}
                                  size='small'
                                  type='outlined'
                                  {...action.iconButtonProps}
                                />
                              )

                              return (
                                <Grid key={index} item xs='auto'>
                                  {action.tooltip &&
                                  action.tooltip(datum) !== null ? (
                                    <Tooltip
                                      placement='top'
                                      title={action.tooltip(datum)}
                                    >
                                      <span>
                                        <ActionItem />
                                      </span>
                                    </Tooltip>
                                  ) : (
                                    <ActionItem />
                                  )}
                                </Grid>
                              )
                            })}
                            {!_.isEmpty(rowActions) && (
                              <Grid item xs='auto'>
                                <CngIconButton
                                  icon={['fal', 'ellipsis-h']}
                                  onClick={(event) => {
                                    event.stopPropagation()
                                    setRowActionsPopover({
                                      anchorEl: event.currentTarget,
                                      rowData: datum
                                    })
                                  }}
                                  size='small'
                                  type='outlined'
                                />
                              </Grid>
                            )}
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell
                    align='center'
                    colSpan={
                      columns.length +
                      (hasActionColumn ? 1 : 0) +
                      (checkboxSelection ? 1 : 0)
                    }
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </MuiTable>
        </div>
        <ScrollBar
          className={classes.bottomScrollBar}
          tableContentRef={tableContentRef}
          onScroll={(event) => {
            const { scrollLeft } = event.target
        
            topScrollBarRef.current.scrollLeft = scrollLeft
            tableContentWrapperRef.current.scrollLeft = scrollLeft
          }}
          ref={bottomScrollBarRef}
        />
        </Box>
        <Box padding={2}>
          <TablePagination
            count={data.totalElements}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            onPageChange={setPage}
            page={page}
          />
        </Box>
        {isFetching && (
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
        )}
      </Card>
      <RowActionsPopover
        anchorEl={rowActionsPopover.anchorEl}
        open={rowActionsPopover.anchorEl ? true : false}
        onClose={() => setRowActionsPopover({ anchorEl: null, rowData: null })}
        rowActions={rowActions}
        rowData={rowActionsPopover.rowData}
      />
      <ExportPopover
        isCSVOnly={customExportData ? true : false}
        anchorEl={exportPopoverAnchorEl}
        open={exportPopoverAnchorEl ? true : false}
        onClose={() => setExportPopoverAnchorEl(null)}
        onExportData={customExportData ? handleCustomExportData : handleExportData}
      />
      <TableColumnsConfigPopover
        anchorEl={tableColsPopoverAnchorEl}
        columns={columns.map((column) => ({
          key: transformColumnKey(column.title),
          title: column.title
        }))}
        onChangeTableCols={(columns) => setTableColumns(columns)}
        open={tableColsPopoverAnchorEl ? true : false}
        onClose={() => setTableColsPopoverAnchorEl(null)}
        tablePreference={tablePreference}
        visibleColumns={visibleColumns}
        handleSave={handleSaveTablePreference}
      />
    </>
  )
}

export default Table

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  fetch: PropTypes.shape({
    url: PropTypes.string.isRequired
  }),
  sortConfig: PropTypes.shape({
    defaultDirection: PropTypes.string.isRequired,
    defaultField: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['column', 'header'])
  }),
  customProp: function (props) {
    const { sortConfig } = props

    if (sortConfig) {
      if (sortConfig.type === 'header' && !sortConfig.defaultField) {
        return new Error(
          `'sortConfig.defaultField' prop is required when 'sortConfig.type' is 'header'.`
        )
      } else {
        if (typeof sortConfig.defaultField !== 'string') {
          return new Error(
            `'sortConfig.defaultField' prop has to be string type.`
          )
        }
      }
    }
  }
}

const StyledPopoverWrapper = withStyles((theme) => ({
  root: {
    maxWidth: '100%',
    padding: 4,
    width: (props) => props.width || theme.breakpoints.values.sm
  }
}))(Paper)

const StyledPopoverHeader = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.sectionOddBg,
    padding: '8px 16px',
    '&::before': {
      display: 'none'
    },
    '& .MuiTypography-root': {
      fontSize: 14,
      fontWeight: 700,
      '&.MuiTypography-root.count': {
        alignItems: 'center',
        backgroundColor: `${theme.palette.primary.main}33`,
        borderRadius: '50%',
        color: theme.palette.primary.main,
        display: 'inline-flex',
        height: 32,
        justifyContent: 'center',
        width: 32
      }
    }
  }
}))(Box)

function TableFilterRow(props) {
  const { columns, filters, filterValues, onChangeFilter, tableColumns, isResetting } = props

  const initDefaultValues = useCallback(() => {
    if (!filters || filters.length === 0) return undefined

    let defaultValues = {}

    filters.forEach((filter) => {
      switch (filter.type) {
        case 'radio': {
          defaultValues[filter.name] = 'all'
          break
        }

        case 'checkbox': {
          defaultValues[filter.name] = []
          break
        }

        case 'daterange': {
          defaultValues[filter.name] = { startDate: null, endDate: null }
          break
        }

        case 'select':
        case 'textfield': {
          defaultValues[filter.name] = ''
          break
        }

        default:
          throw new Error('Invalid field type.')
      }
    })

    return defaultValues
  }, [filters, filterValues])

  const methods = useForm({
    defaultValues: initDefaultValues(),
    shouldUnregister: false
  })

  useEffect(() => {
    if (filterValues) {
      for (let key in filterValues) {
        methods.setValue(key, filterValues[key])
      }
    }
  }, [])

  useEffect(() => {
    const subscription = methods.watch(
      _.debounce((value) => onChangeFilter(value), 1000)
    )

    return () => subscription.unsubscribe()
  }, [methods.watch])

  // Clear filter fields
  useEffect(() => {
    if (isResetting) {
      methods.reset()
    }
  }, [filterValues])

  const isColumnShown = useCallback(
    (columnKey) => {
      return tableColumns.includes(transformColumnKey(columnKey))
    },
    [tableColumns]
  )

  return (
    <FormProvider {...methods}>
      {columns.map((column, colIndex) => {
        if (!isColumnShown(column.title)) return

        const index = filters.findIndex((filter) => filter.name === column.field)
        return (
          <TableCell key={column.field || colIndex}>
            {index !== -1 && <TableFilterField filter={filters[index]} />}
          </TableCell>
        )
      })}
    </FormProvider>
  )
}

function TableFilterField(props) {
  const { filter } = props

  function renderField(filter) {
    switch (filter.type) {
      case 'radio': {
        return <RadioFilterField filter={filter} />
      }
  
      case 'checkbox': {
        return <CheckboxFilterField filter={filter} />
      }

      case 'daterange': {
        return <DateRangeFilterField filter={filter} />
      }

      case 'select': {
        return <SelectFilterField filter={filter} />
      }

      case 'textfield': {
        return (
          <CngTextField
            name={filter.name}
            placeholder='Search'
            hiddenLabel
            size='small'
          />
        )
      }
  
      default:
        throw new Error('Invalid field type.')
    }
  }

  return (
    <Box minWidth={100} maxWidth={150}>
      {renderField(filter)}
    </Box>
  )
}

function RadioFilterField(props) {
  const { filter } = props

  const [popover, setPopover] = useState({ anchorEl: null })
  const { getValues } = useFormContext()

  function handleClosePopover() {
    setPopover({ anchorEl: null })
  }

  function getOptionLabel(value) {
    if (value === 'all') return 'All'

    const option = filter.options.find((option) => option.value === value)
    return option?.label || ''
  }

  return (
    <>
      <CngTextField
        hiddenLabel
        onClick={(event) => setPopover({ anchorEl: event.currentTarget })}
        inputProps={{
          readOnly: true,
          style: { cursor: 'pointer' },
          value: getOptionLabel(getValues(filter.name))
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <ArrowDropDownIcon fontSize='small' />
            </InputAdornment>
          )
        }}
        size='small'
      />
      <Popover
        anchorEl={popover.anchorEl}
        onClose={handleClosePopover}
        open={popover.anchorEl ? true : false}
        {...DEFAULT_POPOVER_PROPS}
      >
        <StyledPopoverWrapper width={200}>
          <CngRadioGroupField
            direction='column'
            size='small'
            labelSpacing={0}
            name={filter.name}
            onChange={handleClosePopover}
            options={[{ label: 'All', value: 'all' }, ...filter.options]}
            style={{ marginLeft: 16, marginRight: 8, padding: 8 }}
          />
        </StyledPopoverWrapper>
      </Popover>
    </>
  )
}

function DateRangeFilterField(props) {
  const { filter } = props
  const { setValue, watch } = useFormContext()
  const { startDate, endDate } = watch(filter.name)
  const [maxDate, setMaxDate] = useState(endDate ? moment(endDate, 'YYYY-MM-DD') : undefined)
  const [minDate, setMinDate] = useState(startDate ? moment(startDate, 'YYYY-MM-DD') : undefined)

  const methods = useForm({ defaultValues: { startDate, endDate }})

  useEffect(() => {
    if (!startDate && !endDate) {
      methods.reset({ startDate: null, endDate: null })
    }
  }, [startDate, endDate])

  useEffect(() => {
    const subscription = methods.watch(
      _.debounce((value) => {
        if (value.startDate && value.endDate) {
          setValue(filter.name, value)
        }
      }, 1000)
    )

    return () => subscription.unsubscribe()
  }, [methods.watch])

  return (
    <FormProvider {...methods}>
      <Box minWidth={160}>
        <Grid justify='center' container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <CngDateField
                  hiddenLabel
                  labelFunc={(date) => date ? date.format('D MMM YYYY') : ''}
                  maxDate={maxDate}
                  name='startDate'
                  onAccept={(date) => setMinDate(date)}
                  placeholder='Start date'
                  size='small'
                />
              </Grid>
              <Grid item xs={12}>
                <CngDateField
                  hiddenLabel
                  labelFunc={(date) => date ? date.format('D MMM YYYY') : ''}
                  onAccept={(date) => setMaxDate(date)}
                  placeholder='End date'
                  minDate={minDate}
                  name='endDate'
                  size='small'
                />
              </Grid>
            </Grid>
          </Grid>
          {(startDate || endDate) && (
            <Grid item xs='auto'>
              <CngIconButton
                icon={['fal', 'times']}
                onClick={() => setValue(filter.name, { startDate: null, endDate: null })}
                size='small'
                type='outlined'
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </FormProvider>
  )
}

function CheckboxFilterField(props) {
  const { filter } = props

  const [popover, setPopover] = useState({ anchorEl: null })
  const { setValue, watch } = useFormContext()
  const value = watch(filter.name)
  const theme = useTheme()

  return (
    <>
      <CngTextField
        hiddenLabel
        onClick={(event) => setPopover({ anchorEl: event.currentTarget })}
        inputProps={{
          readOnly: true,
          style: { cursor: 'pointer' },
          value: value.length === 0 ? 'All' : `${value.length} selected`
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {theme.props?.MuiSelect?.IconComponent ? <theme.props.MuiSelect.IconComponent fontSize='small' /> : <ArrowDropDownIcon fontSize='small' />}
            </InputAdornment>
          )
        }}
        size='small'
      />
      <Popover
        anchorEl={popover.anchorEl}
        onClose={() => setPopover({ anchorEl: null })}
        open={popover.anchorEl ? true : false}
        {...DEFAULT_POPOVER_PROPS}
      >
        <StyledPopoverWrapper width={200}>
          <CheckboxField
            name={filter.name}
            onChange={(data) => setValue(filter.name, data)}
            options={filter.options}
            value={value}
          />
        </StyledPopoverWrapper>
      </Popover>
    </>
  )
}

function SelectFilterField(props) {
  const { filter } = props

  return (
    <CngTextField
      hiddenLabel
      name={filter.name}
      placeholder='Select'
      select
      SelectProps={{
        displayEmpty: true
      }}
      size='small'
    >
      <MenuItem value=''>Select</MenuItem>
      {filter.options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CngTextField>
  )
}

function RowActionsPopover(props) {
  const { anchorEl, onClose, open, rowActions, rowData } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      {...DEFAULT_POPOVER_PROPS}
    >
      <StyledPopoverWrapper width='auto'>
        <MenuList disablePadding>
          {!_.isEmpty(rowActions) &&
            rowData &&
            rowActions.map((action, index) => {
              const ActionMenuItem = () => (
                <MenuItem
                  disabled={action.disabled ? action.disabled(rowData) : false}
                  onClick={() => {
                    action.onClick(rowData)
                    onClose()
                  }}
                >
                  {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
                  <Typography variant='body2' style={{ fontWeight: 700 }}>
                    {action.label}
                  </Typography>
                </MenuItem>
              )

              return action.tooltip && action.tooltip(rowData) !== null ? (
                <Tooltip
                  key={index}
                  placement='top'
                  title={action.tooltip(rowData)}
                >
                  <span>
                    <ActionMenuItem />
                  </span>
                </Tooltip>
              ) : (
                <ActionMenuItem key={index} />
              )
            })}
        </MenuList>
      </StyledPopoverWrapper>
    </Popover>
  )
}

function ExportPopover(props) {
  const { isCSVOnly, anchorEl, onClose, onExportData, open } = props

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      {...DEFAULT_POPOVER_PROPS}
    >
      <StyledPopoverWrapper width={200}>
        <StyledPopoverHeader>
          <Typography variant='body2'>Export as</Typography>
        </StyledPopoverHeader>
        <MenuList>
          <MenuItem
            onClick={() => {
              onExportData('CSV')
              onClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={['fal', 'file-csv']} size='lg' />
            </ListItemIcon>
            <Typography variant='body2' style={{ fontWeight: 700 }}>
              CSV
            </Typography>
          </MenuItem>
          {!isCSVOnly && <MenuItem
            onClick={() => {
              onExportData('PDF')
              onClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon icon={['fal', 'file-pdf']} size='lg' />
            </ListItemIcon>
            <Typography variant='body2' style={{ fontWeight: 700 }}>
              PDF
            </Typography>
          </MenuItem>}
        </MenuList>
      </StyledPopoverWrapper>
    </Popover>
  )
}

function CheckboxField(props) {
  const { name, onChange, options, value } = props

  // Used for validation and data transformation
  const [values, setValues] = useState(value)

  // Returns array of selected values, e.g.: ['draft', 'sent', 'accepted']
  function handleCheckboxGroupChange(event) {
    const result = _.xor(values, [event.target.value])
    onChange(result)
    setValues(result)
  }

  return (
    <Grid container style={{ padding: '8px 16px' }}>
      {options.map((option) => (
        <CngGridItem key={option.value} xs={12}>
          <CngCheckboxField
            checked={option.checked ? option.checked : values.includes(option.value)}
            disabled={option.disabled}
            label={option.label}
            name={name}
            onChange={handleCheckboxGroupChange}
            value={option.value}
            style={{ padding: 8 }}
          />
        </CngGridItem>
      ))}
    </Grid>
  )
}

function TableColumnsConfigPopover(props) {
  const { anchorEl, columns, onChangeTableCols, onClose, open, tablePreference, visibleColumns, handleSave } = props
  const classes = useStyles()

  const methods = useForm({
    defaultValues: columns.reduce(
      (acc, curr) => ({ ...acc, [curr.key]: true }),
      {}
    )
  })

  useEffect(() => {
    if (visibleColumns.length > 0) {
      columns.map((column) => { methods.setValue(column.key, visibleColumns.includes(column.key)) })
    }
  }, [visibleColumns])

  function onSubmit(data) {
    const result = Object.keys(data).filter((column) => data[column])

    onChangeTableCols(result)
  }

  const [submitting, setSubmitting] = useState(false)
  async function onClickSave() {
    setSubmitting(true)
    const visibleColumns = Object.keys(methods.getValues()).filter((column) => methods.getValues()[column])
    handleSave(visibleColumns, setSubmitting)
  }

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={open}
      {...DEFAULT_POPOVER_PROPS}
    >
      <StyledPopoverWrapper width={300}>
        <StyledPopoverHeader>
          <Typography variant='body2'>View columns</Typography>
        </StyledPopoverHeader>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box marginTop={1} maxHeight='50vh' overflow='hidden auto'>
              <Grid container>
                {columns.map((column) => (
                  <Grid
                    key={column.key}
                    className={classes.checkbox}
                    item
                    xs={12}
                  >
                    <CngCheckboxField
                      name={column.key}
                      label={column.title}
                      checked={methods.getValues(column.key)}
                      onChange={(event) => {
                        methods.setValue(column.key, event.target.checked)
                        methods.handleSubmit(onSubmit)()
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </form>
        </FormProvider>

        {(tablePreference.module && tablePreference.key) &&
          <Grid container alignItems='center' justify='center' style={{ padding: '10px 0 5px 0' }}>
            <CngButton disabled={submitting} onClick={onClickSave}>Save</CngButton>
          </Grid>
        }

      </StyledPopoverWrapper>
    </Popover>
  )
}

const ScrollBar = React.forwardRef((props, ref) => {
  const { onScroll, tableContentRef, ...otherProps } = props
  const [width, setWidth] = useState(0)

  // Timeout required to retrive correct offsetWidth
  useEffect(() => {
    setTimeout(() => { setWidth(tableContentRef.current?.offsetWidth) }, 300)
  }, [tableContentRef.current?.offsetWidth])

  return (
    <div
      style={{
        height: 20,
        overflowX: 'scroll',
        overflowY: 'hidden'
      }}
      onScroll={onScroll}
      ref={ref}
      {...otherProps}
    >
      <div style={{ width: width, height: '100%' }} />
    </div>
  )
})