const AceOcnBillofladingKeys = {
  TITLE: "title",
  VOYAGE_REFERENCE: "voyageReference",
  MANIFEST_ID: "manifestId",
  BILL_INDICATOR: "billIndicator",
  BILL_NUMBER: "billNumber",
  ISSUER_SCAC: "issuerScac",
  OCEAN_BILL_NUMBER: "oceanBillNumber",
  NOTIFY_SCAC: "notifyScac",
  CARRIER_SCAC: "carrierScac",
  VESSEL_NAME: "vesselName",
  VOYAGE_NUMBER: "voyageNumber",
  VESSEL_COUNTRY: "vesselCountry",
  PORT_OF_DISCHARGE: "portOfDischarge",
  E_T_A: "eTA",
  PLACEOF_RECIEPT: "placeofReciept",
  PORT_OF_LOADING: "portOfLoading",
  LAST_FOREIGN_PORT: "lastForeignPort",
  PARTY_ID: "partyId",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  TEMPLATE_NAME: "templateName",
  TEMPLATE_FLAG: "templateFlag",
  MODE_OF_SUBMISSION: "modeOfSubmission",
  STATUS: "status",
  TOTAL_PACKAGES: "totalPackages",
  TOTAL_PACKAGES_UOM: "totalPackagesUom",
  TOTAL_GROSS_WEIGHT: "totalGrossWeight",
  TOTAL_GROSS_WEIGHT_UOM: "totalGrossWeightUom",
  TOTAL_VOLUME: "totalVolume",
  TOTAL_VALUE_UOM: "totalValueUom",
  MESSAGE_FUN_CODE: "messageFunCode",

  MSG_FUNC_ORIGINAL: "msgFuncOriginal",
  MSG_FUNC_CHANGE: "msgFuncChange",
  MSG_FUNC_CANCEL: "msgFuncCancel",
  SUBMIT_BUTTON: "submitButton",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  DETACH_BUTTON: "detachButton",
  DETACH_SENT_RECORD: "detachSentRecord",
  EDIT_BUTTON: "editButton",
  EDIT_SENT_RECORD: "editSentRecord",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  DELETE_NOT_DRAFT_RECORD: "deleteNotDraftRecord",
  DISPOSITION_CODE: "dispositionCode",
  ATTACH_BILL_SUCESS_MSG: "attachBillSuccessMsg",
  ATTACH: "attach",
  ATTACH_SENT: "attachSentRecord",
  SAVE_BUTTON:"saveButton",
  UPDATE_BUTTON:"updateButton",
  BACK_BUTTON:"backButton",
  DETACH_SUCCESS_MESSAGE:"detachSuccessMsg",
  DISCARD_BUTTON:"discardButton",
  SAVE_SUCCESS_MSG:"saveSuccessMsg",
  UPDATE_SUCCESS_MSG:"updateSuccessMsg",
  DELETE_SUCCESS_MSG:"deleteSuccessMsg",
  SELECT_RECORD_SUBMIT:"selectRecordSubmit",
  SAVE_FLAG: "saveFlag",
  EDIT_DISABLED: "editDisabled",
  DELETE_DISABLED: "deleteDisabled",
  CREATED_DATE: "createdDate",
  PRINTBOL: "printBol",
  PRINTRESPONSE: "printResponse",
  BOL_SCAC_INFO:"bolScacInfo",
  VOYAGE_REF_SCAC_INFO :"voyageScacInfo",
  CREATED_BY_LOGIN_ID: "createdByLoginId",
  CommercialParties: {
    TITLE: "commercialParties.title",
    PARTY_DROPDOWN: "commercialParties.partyDropDown",
    PARTY_IDENTIFIER: "commercialParties.partyIdentifier",
    PARTY_TYPE: "commercialParties.partyType",
    PARTY_NAME: "commercialParties.partyName",
    ADDRESS1: "commercialParties.address1",
    ADDRESS2: "commercialParties.address2",
    ADDRESS3: "commercialParties.address3",
    TEL_NO: "commercialParties.telNo"
  },
  ContainerDetails: {
    TITLE: "containerDetails.title",
    HEADER_ID: "containerDetails.headerId",
    CONTAINER_NUMBER: "containerDetails.containerNumber",
    TYPE_CODE: "containerDetails.typeCode",
    DESCRIPTION_CODE: "containerDetails.descriptionCode",
    SEAL_NUMBER: "containerDetails.sealNumber",
    SERVICE_CODE: "containerDetails.serviceCode",
    CONTAINER_STATUS: "containerDetails.containerStatus"
  },
  GoodsDetails: {
    TITLE: "goodsDetails.title",
    HEADER_ID: "goodsDetails.headerId",
    CONTAINER_ID: "goodsDetails.containerId",
    CONTAINER_DESC: "goodsDetails.containerDesc",
    GOODS_DESC: "goodsDetails.goodsDesc",
    NUMBER_OF_PACKAGES: "goodsDetails.numberOfPackages",
    PACKAGE_UOM: "goodsDetails.packageUom",
    MARKS_AND_NUM: "goodsDetails.marksAndNum",
    GROSS_WEIGHT: "goodsDetails.grossWeight",
    GROSS_WEIGHT_UOM: "goodsDetails.grossWeightUom",
    VOLUME: "goodsDetails.volume",
    VOLUME_U_O_M: "goodsDetails.volumeUOM"
  },
  DGgoodsDetails: {
    TITLE: "dGgoodsDetails.title",
    GOODS_DETAILS: "dGgoodsDetails._goodsDetailsId",
    HEADER_ID: "dGgoodsDetails.headerId",
    CONTAINER_ID: "dGgoodsDetails.containerId",
    IMDG_NO: "dGgoodsDetails.imdgNo",
    FLASH_POINT_TEMP: "dGgoodsDetails.flashPointTemp",
    DG_CODE: "dGgoodsDetails.dgCode",
    DG_CLASS: "dGgoodsDetails.dgClass",
    DG_CODE_QUAL: "dGgoodsDetails.dgCodeQual",
    HAZARDOUS_DESC: "dGgoodsDetails.hazardousDesc",
    HAZARDOUS_PAGE_NO: "dGgoodsDetails.hazardousPageNo",
    MATERIAL_DESC: "dGgoodsDetails.materialDesc",
    MATERIAL_CLASS: "dGgoodsDetails.materialClass",
    EMERGENCY_CONTACT: "dGgoodsDetails.emergencyContact",
    NEGATIVE:"dGgoodsDetails.negative"
  },
  Validations: {
    ERR_MSG_ALPHA_NUMERIC: "validations.errMsgAlphaNumeric",
    ERR_MSG_ALPHA_NUMERIC_SPACE: "validations.errMsgAlphaNumericSpace",
    ERR_MSG_ALPHA_NUMERIC_SPACE_DOT: "validations.errMsgAlphaNumericSpaceDot",
    ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR: "validations.errMsgAlphaNumericSpecChar",
    ERR_MSG_NUMERIC: "validations.errMsgNumeric",
    ERR_MSG_NUMERIC_HYPHEN_SPACE: "validations.errMsgNumericHyphenSpace"
  }
}

export default AceOcnBillofladingKeys
