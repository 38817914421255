import React, { useContext } from 'react'
import { Grid, Typography,Box, Tooltip } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { ACEOceanTranslationContext } from '../../contexts/ACEOceanTranslationContext'
import NaUSForeignPortAutocompleteField from '../../../../components/na/autocomplete/codemaintenance/NaUSForeignPortAutocompleteField'
import CngSection from 'src/components/cngcomponents/CngSection'
import { NaUSPortAutocompleteField } from 'src/components/na'
import { useWatch,useFormContext } from "react-hook-form"

const {
    form: {
        field: {
            CngTextField,
            CngDateField,
            CngCountryAutocompleteField,
        }
    },
    CngGridItem,
    button: { CngButton ,CngIconButton}
} = components

function VoyageReferenceSection(props) {
    const { disabled, shouldHideMap } = props
    const { getTranslatedText } = useContext(ACEOceanTranslationContext)
    const { vesselCountry,portOfDischarge,portOfLoading,lastForeignPort } = useWatch()
    const { setValue, getValues, reset, watch, trigger} = useFormContext();
    
    return (
        <Grid item xs={12} md={6}>
            <CngSection title={getTranslatedText('BOL', 'voyageReference')}
                subheader={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography color='textSecondary' variant='caption'>
                            <Typography variant='inherit' color='error'>*</Typography>
                            Mandatory fields
                        </Typography>
                        <Tooltip placement='right' title={getTranslatedText('BOL', 'voyageScacInfo')}>
                            <span>
                                <CngIconButton
                                    icon={['fal', 'info-circle']}
                                    size='small'
                                    type='outlined'
                                />
                            </span>
                        </Tooltip>
                    </Box>
                }>    
                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierScac}>
                        <CngTextField
                            name="carrierScac"
                            label={getTranslatedText('BOL', 'carrierScac')}
                            disabled={disabled}
                            required
                            size='small'
                            onChange={(e) => {
                                setValue('carrierScac', e.target.value.toUpperCase(), {
                                  shouldValidate: true
                                })
                            }}
                            onBlur={(e) => {
                                if (e.target.value !== undefined) {
                                    setValue('carrierScac', e.target.value.trim(), {
                                    shouldValidate: true
                                    })
                                }
                            }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselName}>
                        <CngTextField
                            name="vesselName"
                            label={getTranslatedText('BOL', 'vesselName')}
                            disabled={true}
                            size='small'
                            onChange={(e) => {
                                setValue('vesselName', e.target.value.toUpperCase(), {
                                  shouldValidate: true
                                })
                            }}
                            onBlur={(e) => {
                                if (e.target.value !== undefined) {
                                    setValue('vesselName', e.target.value.trim(), {
                                    shouldValidate: true
                                    })
                                }
                            }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNumber}>
                        <CngTextField
                            name="voyageNumber"
                            label={getTranslatedText('BOL', 'voyageNumber')}
                            disabled={true}
                            size='small'
                            onChange={(e) => {
                                setValue('voyageNumber', e.target.value.toUpperCase(), {
                                  shouldValidate: true
                                })
                            }}
                            onBlur={(e) => {
                                if (e.target.value !== undefined) {
                                    setValue('voyageNumber', e.target.value.trim(), {
                                    shouldValidate: true
                                    })
                                }
                            }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselCountry}>
                        <CngCountryAutocompleteField
                            name="vesselCountry"
                            label={getTranslatedText('BOL', 'vesselCountry')}
                            disabled={true}
                            size='small'
                            lookupProps={{
                                label: (record) => `${record.code};${record.descriptionEn}`
                              }}
                            key={vesselCountry}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfDischarge}>
                        <NaUSPortAutocompleteField
                            name="portOfDischarge"
                            label={getTranslatedText('BOL', 'portOfDischarge')}
                            disabled={true}
                            size='small'
                            key={portOfDischarge}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.etaDateArr}>
                        <CngDateField
                            name="etaDateArr"
                            label={getTranslatedText('BOL', 'etaDateArr')}
                            disabled={true}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeofReciept}>
                        <CngTextField
                            name="placeofReciept"
                            label={getTranslatedText('BOL', 'placeofReciept')}
                            disabled={disabled}
                            required
                            size='small'
                            onChange={(e) => {
                                setValue('placeofReciept', e.target.value.toUpperCase(), {
                                  shouldValidate: true
                                })
                            }}
                            onBlur={(e) => {
                                if (e.target.value !== undefined) {
                                    setValue('placeofReciept', e.target.value.trim(), {
                                    shouldValidate: true
                                    })
                                }
                            }}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoading}>
                        <NaUSForeignPortAutocompleteField
                            name="portOfLoading"
                            label={getTranslatedText('BOL', 'portOfLoading')}
                            disabled={disabled}
                            required
                            size='small'
                            key={portOfLoading}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.lastForeignPort}>
                        <NaUSForeignPortAutocompleteField
                            name="lastForeignPort"
                            label={getTranslatedText('BOL', 'lastForeignPort')}
                            disabled={disabled}
                            required
                            size='small'
                            key={lastForeignPort}
                        />
                    </CngGridItem>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default VoyageReferenceSection