import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Namespace from '../../../constants/locale/Namespace'
import SbciManifestKeys from '../../../constants/locale/key/SbciManifest'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useTranslation,
  useServices,
  constants
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SbciManifestTemplateAutoComplete from './autocomplete/SbciManifestTemplateAutoComplete'
import FirmCodesAutocompleteField from '../../../components/na/autocomplete/codemaintenance/FirmCodesAutocompleteField'
import NaUSPortAutocompleteField from '../../../components/na/autocomplete/codemaintenance/NaUSPortAutocompleteField'
import { useFormContext } from 'react-hook-form'
import Switch from '../../../components/Switch'
import CngSection from '../../../components/cngcomponents/CngSection'
import InvoiceAttachTable from './InvoiceAttachTable'
import { Box, Grid, InputAdornment, Typography } from '@material-ui/core'
import { fetchUser } from "src/views/userprofile/UserProfileService.js";
import { FileForUserGetUserDetails, FileForUserGetPartyId } from 'src/common/FileForUserCommon'

const {
  form: {
    field: {
      CngCheckboxField,
      CngTextField,
      CngDateField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem
} = components

const {
  filter: { EQUAL }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: '',
  version: '',
  templateNameDropDown: '',
  manifestNo: '',
  clientCode: '',
  filerCode: '',
  firmsCode: '',
  arrivalDate: '',
  manifestDate: '',
  portEntryCode: '',
  portOfArrival: '',
  description: '',
  transportMode: '',
  licensePlateNo: '',
  personInCharge: '',
  totalEnteredValue: '',
  totalGrossWt: '',
  totalNetWt: '',
  totalFreightCharge: '',
  hazmatInd: 'N',
  status: '',
  corpid: '',
  submittedBy: '',
  submittedDate: '',
  templateFlag: false,
  templateName: '',
  sbciInvoices: [],
  partyId: ''
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { id } = useParams()
  const { setValue, trigger, watch,getValues } = useFormContext()

  const status = watch('status')
  const templateFlag = watch('templateFlag')
  const hazmatInd = watch('hazmatInd')
  const transportMode = watch('transportMode')
  const portEntryCode = watch('portEntryCode')
  const portOfArrival = watch('portOfArrival')

  const { translate } = useTranslation(Namespace.SBCI_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { securedSendRequest } = useServices()

  const [user, setUser] = useState([]);
  watch("fileForUserId");
  watch("fileForUserPartyId");
  watch("fileForUserLoginId");

  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if(fileForUserDetails!=null && fileForUserDetails!=undefined){
      setValue("fileForUserId",fileForUserDetails.fileForUserId)
      setValue("fileForUserPartyId",fileForUserDetails.fileForUserPartyId)
      setValue("fileForUserLoginId",fileForUserDetails.fileForUserLoginId)
    }

    fetchUser(setUser, securedSendRequest, fileForUserDetails);
  }, []);

  useEffect(()=>{

    let clientCode = ""
    if (user && user.upsSacClientId) {
      Object.entries(user.upsSacClientId).forEach((item) => {
          for (const key in item[1]) {
              if (key == 'upsSacClientId') {
                clientCode = item[1][key];
              }
          }

          let value = getValues("clientCode")
          if ((value == null || value == undefined || value == "")) {
            if(clientCode.indexOf("~") !== -1){
              setValue("clientCode",clientCode.substring(0,clientCode.indexOf("~")));
            }else{
              setValue("clientCode",clientCode)
            }
          }
      })
    }
  },[user]);

  function makeTranslatedTextsObject() {
    const manifest = translate(Namespace.SBCI_MANIFEST, SbciManifestKeys.TITLE)
    const templateFlag = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TEMPLATE_FLAG
    )
    const templateName = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TEMPLATE_NAME
    )
    const manifestNo = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.MANIFEST_NO
    )
    const manifestDate = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.MANIFEST_DATE
    )
    const transportMode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TRANSPORT_MODE
    )
    const clientCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.CLIENT_CODE
    )
    const firmsCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.FIRMS_CODE
    )
    const licensePlateNo = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.LICENSE_PLATE_NO
    )
    const personInCharge = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.PERSON_IN_CHARGE
    )
    const arrivalDate = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.ARRIVAL_DATE
    )
    const portEntryCode = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.PORT_ENTRY_CODE
    )
    const portOfArrival = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.PORT_OF_ARRIVAL
    )
    const hazmatInd = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.HAZMAT_IND
    )
    const description = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.DESCRIPTION
    )
    const totalEnteredValue = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TOTAL_ENTERED_VALUE
    )
    const totalGrossWt = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TOTAL_GROSS_WT
    )
    const totalNetWt = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TOTAL_NET_WT
    )
    const totalFreightCharge = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.TOTAL_FREIGHT_CHARGE
    )
    const viewAttachInvoices = translate(
      Namespace.SBCI_MANIFEST,
      SbciManifestKeys.VIEW_ATTACH_INVOICES
    )

    return {
      manifest,
      templateFlag,
      templateName,
      manifestNo,
      manifestDate,
      transportMode,
      clientCode,
      firmsCode,
      licensePlateNo,
      personInCharge,
      arrivalDate,
      portEntryCode,
      portOfArrival,
      hazmatInd,
      description,
      totalEnteredValue,
      totalGrossWt,
      totalNetWt,
      totalFreightCharge,
      viewAttachInvoices,
    }
  }

  function handleApplyTemplate(template) {
    if (!template) return

    const { data } = template

    const {
      manifestNo,
      clientCode,
      firmsCode,
      arrivalDate,
      manifestDate,
      portEntryCode,
      portOfArrival,
      description,
      transportMode,
      licensePlateNo,
      personInCharge,
      totalEnteredValue,
      totalGrossWt,
      totalNetWt,
      totalFreightCharge,
      hazmatInd
    } = data

    setValue('manifestNo', manifestNo || '')
    //setValue('clientCode', clientCode || '')
    setValue('firmsCode', firmsCode || '')
    setValue(
      'arrivalDate',
      arrivalDate ? DateTimeFormatter.toClientDate(arrivalDate) : ''
    )
    setValue(
      'manifestDate',
      manifestDate ? DateTimeFormatter.toClientDate(manifestDate) : ''
    )
    setValue('portEntryCode', portEntryCode || '')
    setValue('portOfArrival', portOfArrival || '')
    setValue('description', description || '')
    setValue('transportMode', transportMode || '')
    setValue('licensePlateNo', licensePlateNo || '')
    setValue('personInCharge', personInCharge || '')
    setValue('totalEnteredValue', totalEnteredValue || '')
    setValue('totalGrossWt', totalGrossWt || '')
    setValue('totalNetWt', totalNetWt || '')
    setValue('totalFreightCharge', totalFreightCharge || '')
    setValue('hazmatInd', hazmatInd || '')

    trigger()
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection
            title={translatedTextsObject.manifest}
            subheader={
              <Typography color='textSecondary' variant='caption'>
                <Typography variant='inherit' color='error'>*</Typography>
                Mandatory fields
              </Typography>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} lg={3} shouldHide={shouldHideMap?.templateFlag}>
                    <CngCheckboxField
                      label={
                        <Typography style={{ fontSize: 12, lineHeight: 1.2 }}>
                          {translatedTextsObject.templateFlag}
                        </Typography>
                      }
                      name='templateFlag'
                      disabled={disabled}
                      onChange={(event) =>
                        setValue('templateFlag', event.target.checked)
                      }
                      size='small'
                      style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
                      value={!templateFlag ? false : true}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} lg shouldHide={shouldHideMap?.templateName}>
                    <CngTextField
                      name='templateName'
                      label={translatedTextsObject.templateName}
                      disabled={!templateFlag}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} lg={3}>
                    <SbciManifestTemplateAutoComplete
                      name='templateNameDropDown'
                      label='Auto-fill using template'
                      disabled={disabled}
                      onChange={(_, template) => handleApplyTemplate(template)}
                      size='small'
                      fullWidth
                      disableClearable
                      textFieldProps={{
                        InputProps: {
                          customEndAdornment: () => (
                            <InputAdornment position='end' style={{ marginTop: -16 }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={48} height={48}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} />
                              </Box>
                            </InputAdornment>
                          )
                        }
                      }}
                      forcePopupIcon={false}
                      lookupProps={{
                        filters: [{
                          field: 'partyId',
                          operator: EQUAL,
                          value: FileForUserGetPartyId()
                        }]
                      }}
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.manifestNo}>
                    <CngTextField
                      name='manifestNo'
                      disabled={disabled}
                      label={translatedTextsObject.manifestNo}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.manifestDate}>
                    <CngDateField
                      name='manifestDate'
                      disabled={disabled}
                      label={translatedTextsObject.manifestDate}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.transportMode}>
                    <CngCodeMasterAutocompleteField
                      key={transportMode}
                      codeType='SBCI_TRANSPORT_MODE'
                      name='transportMode'
                      disabled={disabled}
                      label={translatedTextsObject.transportMode}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.clientCode}>
                    <CngTextField
                      name='clientCode'
                      disabled={true}
                      label={translatedTextsObject.clientCode}
                      size='small'
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.firmsCode}>
                    <FirmCodesAutocompleteField
                      name='firmsCode'
                      disabled={disabled}
                      label={translatedTextsObject.firmsCode}
                      size='small'
                      key={getValues('firmsCode')}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.licensePlateNo}>
                    <CngTextField
                      name='licensePlateNo'
                      disabled={disabled}
                      label={translatedTextsObject.licensePlateNo}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.personInCharge}>
                    <CngTextField
                      name='personInCharge'
                      disabled={disabled}
                      label={translatedTextsObject.personInCharge}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.arrivalDate}>
                    <CngDateField
                      name='arrivalDate'
                      disabled={disabled}
                      label={translatedTextsObject.arrivalDate}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.portEntryCode}>
                    <NaUSPortAutocompleteField
                      key={portEntryCode}
                      name='portEntryCode'
                      disabled={disabled}
                      label={translatedTextsObject.portEntryCode}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.portOfArrival}>
                    <NaUSPortAutocompleteField
                      key={portOfArrival}
                      name='portOfArrival'
                      disabled={disabled}
                      label={translatedTextsObject.portOfArrival}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.hazmatInd}>
                    <Switch
                      fullWidth
                      checked={hazmatInd === 'Y'}
                      name='hazmatInd'
                      label={translatedTextsObject.hazmatInd}
                      disabled={disabled}
                      onChange={(event) => setValue('hazmatInd', event.target.checked ? 'Y' : 'N')}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.description}>
                    <CngTextField
                      name='description'
                      disabled={disabled}
                      label={translatedTextsObject.description}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.totalEnteredValue}>
                    <CngTextField
                      name='totalEnteredValue'
                      disabled={disabled}
                      label={translatedTextsObject.totalEnteredValue}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.totalGrossWt}>
                    <CngTextField
                      name='totalGrossWt'
                      disabled={disabled}
                      label={translatedTextsObject.totalGrossWt}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.totalNetWt}>
                    <CngTextField
                      name='totalNetWt'
                      disabled={disabled}
                      label={translatedTextsObject.totalNetWt}
                      size='small'
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={6} lg={3} shouldHide={shouldHideMap?.totalFreightCharge}>
                    <CngTextField
                      name='totalFreightCharge'
                      disabled={disabled}
                      label={translatedTextsObject.totalFreightCharge}
                      size='small'
                      required
                    />
                  </CngGridItem>
                </Grid>
              </Grid>
            </Grid>
          </CngSection>
        </Grid>
        {id && (
          <Grid item xs={12}>
            <CngSection title={translatedTextsObject.viewAttachInvoices}>
              <InvoiceAttachTable
                manifestId={id}
                manifestStatus={status}
                showNotification={showNotification}
              />
            </CngSection>
          </Grid>
        )}
      </Grid>
    </>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData)
  localData.arrivalDate = DateTimeFormatter.toClientDate(localData.arrivalDate)
  localData.manifestDate = DateTimeFormatter.toClientDate(
    localData.manifestDate
  )
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  )
  return localData
}

function toServerDataFormat(localData) {
  localData.arrivalDate = DateTimeFormatter.toServerDate(localData.arrivalDate)
  localData.manifestDate = DateTimeFormatter.toServerDate(
    localData.manifestDate
  )
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  )
  return DataFlattener.unflatten(localData)
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
