import { Box, Card, Grid, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import PartyMasterApiUrls from 'src/apiUrls/PartyMasterApiUrls'
import CrewMasterApiUrls from 'src/apiUrls/CrewMasterApiUrls'
import EquipmentMasterApiUrls from 'src/apiUrls/EquipmentMasterApiUrls'
import ConveyanceMasterApiUrls from 'src/apiUrls/ConveyanceMasterApiUrls'
import { FileForUserGetUserDetails } from '../../../common/FileForUserCommon'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import _ from 'lodash'
import useCustomYupValidationResolver from 'src/components/aciacehighway/hooks/useCustomYupValidationResolver'
import ErrorMessageSection from 'src/views/common/ErrorMessageSection'

const {
  button: { CngButton },
  dropzone: { CngFileUpload },
  form: {
    field: { CngSelectField }
  }
} = components

const schema = Yup.object({
  messageType: Yup.string().required('This is required'),
  file: Yup.array().min(1)
})

function UploadMasterData({ showNotification }) {
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [messageType, setMessageType] = useState('PM')
  const [module, setModule] = useState('ACEHWY')

  const methods = useForm({
    defaultValues: { messageType: 'PM', module: 'ACEHWY', file: [] },
    resolver: useCustomYupValidationResolver(schema)
  })
  const file = methods.watch('file')
  const { errors } = methods.formState

  const { securedSendRequest } = useServices()

  function handleUploadFile(data) {
    const { messageType, file = [], module } = data
    setLoading(true)

    function getUrl(messageType) {
      switch (messageType) {
        case 'PM':
          return PartyMasterApiUrls.UPLOAD
        case 'CM':
          return CrewMasterApiUrls.UPLOAD
        case 'EM':
          return EquipmentMasterApiUrls.UPLOAD
        case 'VM':
          return ConveyanceMasterApiUrls.UPLOAD
      }
    }

    const fileForUserDetails = FileForUserGetUserDetails()
    const formData = new FormData()

    formData.append('fileForUserDetails', fileForUserDetails ? JSON.stringify(fileForUserDetails) : '{}')
    formData.append('file', file[0].file)
    formData.append('module', module)

    securedSendRequest.execute('POST', getUrl(messageType), formData,
      (response) => {
        if (response.status === 200) {
          const { errorMessages } = response.data

          if (!errorMessages) {
            showNotification('success', 'File has been uploaded successfully')
            setErrorMessages([])
          } else {
            setErrorMessages(errorMessages)
          }
        }
      },
      (error) => {
        console.error(error)
        showNotification('error', 'Error while uploading file')
      },
      () => {
        setLoading(false)
        methods.setValue('file', [])
      },
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  }

  function masterTemplateFile() {
    const element = document.createElement("a");
    let moduleType = module === 'ACEHWY' ? 'ACE' : 'ACI'
    switch (messageType) {
      case 'PM':
        const blobPM = new Blob(["SNo,PartyId,PartyType,PartyName1,PartyName2,Dba,Address1,Address2,Address3,City,Postalcode,State,Country,Contactperson,TelNo,FaxNo,Email,CtpatInd,PartyIdentifierCode,PartyIdentifier,Division,LocPortCode,PoBoxNumber,CrNo\n,O-Optional,AN-AlphaNumeric,A-Alpha Characters,C-Conditional,,,,,,,,,,,,,,,,,,,\n,\"M\,1..35 AN\"\,\"M\,2..3 AN\"\,\"M\,1..70 AN\"\,\"O\,1..70 AN\"\,\"O\,1..35 AN\"\,\"M\,1..35 AN\"\,\"O\,1..35 AN\"\,\"O\,1..35 AN\"\,\"M\,1..35 AN\"\,\"C,0..9 AN\"\,\"C,0..9 AN\"\,\"M\,1..3AN\"\,\"O\,1..35 AN\"\,\"O\,1..35 AN\"\,\"O\,1..36 AN\"\,\"O\,1..255 AN\"\,\"M\,1AN(YorN)\"\,\"O\,1..3 AN\"\,\"C\,1..15 AN\"\,\"O\,1..30 AN\"\,\"O\,1..5 AN\"\,\"O\,1..30 AN\"\,\"O\,1..12 AN\"\n1,Party1,CN,First,Party,1,Address11,Address12,Address13,HOUSTON,54651,TX,US,Party1,111111111,1,party1@gmail.com,Y,109,111,Div1,1,9001,8001\n2,Party2,\"CZ\,CN\,EX\",Second,Party,2,-,Address22,Address23,HOUSTON,54792,TX,US,Party2,222222222,2,party2@gmail.com,N,275,222,Div2,2,9002,8002"], { type: "text/csv" })
        element.href = window.URL.createObjectURL(blobPM)
        element.download = moduleType + "_PARTYMASTER_UPLOAD.csv";
        document.body.appendChild(element);
        element.click();
        element.parentNode.removeChild(element);
        return;
      case 'CM':
        if (module === 'ACEHWY') {
          const blobACECM = new Blob(["No,CrewId,Activated,ACEId,SCAC,FirstName,MiddleName,LastName,Gender,DOB,Nationality,Address1,Address2,Address3,PostalCode,CityName,State,PassportNo,CountryofPassportRegistration,LaserVisa/BorderCrossingCardNo,EnhancedDriversLicenseId,EnhancedTribalCard,MilitaryIdDocument,USAlienRegistrationCardA2,SentriCard,DriversLicenseNo,USPermanentResidentCardC1,USPassportCard,USAlienRegistrationCardA1,VisaImmigrant,USMerchantMarinerDocumentId,NexusCard,PermanentResidentCardC2,CountryofIssuanceforPRCard,VisaNonImmigrant,DhsReEntryPermitNumber,DhsRefugeeTravelDocument,FastCard,DriverLicenseNo,Country,State/Province,HazmatEndorsement\n,(forlookuppurpose),(registeredinACE)?,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,\n,\"M\,1..15AN\"\,\"M\,1AN(YorN)\"\,\"O\,1..10AN\"\,\"M\,4AN\"\,\"M\,1..40AN\"\,\"O\,1..40AN\"\,\"M\,1..40AN\"\,\"O\,0..35AN\"\,\"M\,(yyyy/mm/dd)\"\,\"M\,2AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,010\"\,\"O\,035\"\,\"O\,23AN\"\,\"O\,0..35AN\"\,\"O\,0..2AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..2AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"O\,0..35AN\"\,\"M\,0..35AN\"\,\"M\,0..2AN\"\,\"O\,2..3AN\"\,\"M\,1AN(YorN)\"\n1,SAMPLEFAST,N,,TEST,JERZY,,SZOT,M,1999/10/24,CA,,,,,,,JW034190,CA,,,,,,,,,,,,,,,,,,,'42700100000000,S97614000560630,CA,ON,N\n2,SAMPLEACE,Y,8150695,TEST,IULIAN,,NITA,M,1999/10/25,CA,,,,,,,WN108779,CA,,,,,,,,,,,,,,,,,,,,N46863770590506,CA,ON,N"], { type: "text/csv" })
          element.href = window.URL.createObjectURL(blobACECM)
        } else {
          const blobACICM = new Blob(["No,Crew Id,Crew Type,Carrier Code,First Name,Middle Name,Last Name,DOB,Nationality,Cell phone,Email,Passport No,Country,Expiry Date,Citizenship Doc No,Country,Expiry Date,Birth Certificate No,Country,Expiry Date,Certificate Of Indian Status Card,Country,Expiry Date,Driver License No,Country,Expiry Date\n,(for lookup purpose),,,,,,,,,,,,,,,,,,,,,,,,\n,\"M\, 1..15 AN\"\,\"M\, 1..9 AN\"\,\"M\, 4 AN\"\,\"M\, 1..40 AN\"\,\"O\, 1..40 AN\"\,\"M\, 1..40 AN\"\,\"M\, (MM/DD/YYYY)\"\,\"M\, 1..3 AN\"\,\"O\, 0..30 N\"\,\"O\,0..50 AN\"\,\"M\, 0..35 AN\"\,\"M\, 2 AN\"\,\"M\, MM/DD/YYYY\"\,\"M\, 0..35 AN\"\,\"M\, 2 AN\"\,\"M\, MM/DD/YYYY\"\,\"M\, 0..35 AN\"\,\"M\, 2 AN\"\,\"M\, MM/DD/YYYY\"\,\"M\, 0..35 AN\"\,\"M\, 2 AN\"\,\"M\, MM/DD/YYYY\"\,\"M\, 0..35 AN\"\,\"M\, 2 AN\"\,\"M\, MM/DD/YYYY\"\n1,UPSSAMPLEFAST1,EJ,TEST,Larry,M,Joe,9/5/2000,CA,111111111,abc@mail.com,ACIPASSPORT1,CA,1/5/2011,CTZ1,CA,2/5/2011,,,,IND1,CA,3/5/2011,CDL1,CA,4/5/2011\n2,UPSACITEST,EJ,TEST,John,K,Johnson,5/2/1989,CA,222222222,abc@mail.com,ACIPASSPORT2,CA,1/6/2011,,,,BC2,CA,2/6/2011,,,,,,"], { type: "text/csv" })
          element.href = window.URL.createObjectURL(blobACICM)
        }
        element.download = moduleType + "_DRIVER_CREW_UPLOAD.csv";
        document.body.appendChild(element);
        element.click();
        element.parentNode.removeChild(element);
        return;
      case 'EM':
        if (module === 'ACEHWY') {
          const blobACEEM = new Blob(["No,Equipment Id,Activated,ACE Id,SCAC,Equipment Number,Equipment Type,License Plate No 1,License Plate #1,License Plate #1\n,(For lookup purpose),(Registered in ACE?),,,,,,(Country of Registration),(Province of Registration)\n,\"M\,1..15AN\"\,\"M\,1AN(Y or N)\"\,\"O\,1..10AN\"\,\"M\,4AN\"\,\"M\,1..18AN\"\,\"M\,2AN\"\,\"M\,1..10AN\"\,\"M\,2AN\"\,\"M\,2..3AN\"\n1,SAMPLE,N,,6CLG,21,FT,S5338,CA,ON"], { type: "text/csv" })
          element.href = window.URL.createObjectURL(blobACEEM)
        } else {
          const blobACIEM = new Blob(["No,Equipment Id,Carrier Code,Equipment Number,Equipment Type,License Plate No 1,Country of Registration,Province of Registration\n,(For lookup purpose),,,,,,\n,\"M\, 1..15AN\"\,\"M\, 4AN\"\,\"M\, 1..18AN\"\,\"M\, 2AN\"\,\"M\, 1..10AN\"\,\"M\, 2AN\"\,\"M\, 2AN\"\n1,bb01,77yy,dd01,tl,sadsaas1,us,ak\n2,bb02,77yy,dd02,tl,sadsaas2,us,ak"], { type: "text/csv" })
          element.href = window.URL.createObjectURL(blobACIEM)
        }
        element.download = moduleType + "_EQUIPMENT_UPLOAD.csv";
        document.body.appendChild(element);
        element.click();
        element.parentNode.removeChild(element);
        return;
      case 'VM':
        if (module === 'ACEHWY') {
          const blobACEVM = new Blob(["No,Conveyance Id,Activated,ACE Id,SCAC,Conveyance #,Conveyance,VIN #,Transponder #,License Plate1,Country1,Province1\n,(for lookup purposes),(registered in ACE?),,,,Type,,,,,\n,\"M\,1.. 15AN\"\,\"M\,1AN (Y or N)\"\,\"O\,1..10AN\"\,\"M\,4AN\"\,\"M\,1..23AN\"\,\"M\,2AN\"\,\"M\,1..30AN\"\,\"O\,0..16AN\"\,\"M\,1..10AN\",,\n1,SAMPLE ,N,,6CLG,101,TR,2HS SAMPLE ,,PBD329,CA,ON"], { type: "text/csv" })
          element.href = window.URL.createObjectURL(blobACEVM)
        } else {
          const blobACIVM = new Blob(["No,Conveyance Id,Carrier Code,Conveyance Number,Conveyance Type,Email,License Plate No,Country of Registration,Province\n,(for lookup purposes),,,,,,,\n,\"M\, 1.. 15AN\"\,\"M\, 4AN\"\,\"M\, 1..23AN\"\,\"M\, 2AN\"\,\"O, 0..50AN\"\,\"M\, 1..10AN\"\,\"M\, 2AN\"\,\"M\, 2AN\"\n1,sasaa11,eeee,wqe101,TV,ac@g.com,sdsdfs1,ca,ab\n2,sasaa21,eeee,wqe111,TV,ac@g.com,sdsdfs2,ca,ab"], { type: "text/csv" })
          element.href = window.URL.createObjectURL(blobACIVM)
        }
        element.download = moduleType + "_CONVEYANCE_UPLOAD.csv";
        document.body.appendChild(element);
        element.click();
        element.parentNode.removeChild(element);
        return;
    }
  }

  return (
    <Card>
      <Box padding={2}>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} lg={3}>
                  <CngSelectField
                    name='messageType'
                    label='Message Type'
                    items={[
                      { text: 'Party Master', value: 'PM' },
                      { text: 'Crew Master', value: 'CM' },
                      { text: 'Equipment Master', value: 'EM' },
                      { text: 'Conveyance Master', value: 'VM' }
                    ]}
                    onChange={(event) =>
                      setMessageType(event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                  <CngSelectField
                    name='module'
                    label='Module'
                    items={[
                      { text: 'ACE Highway', value: 'ACEHWY' },
                      { text: 'ACI Highway', value: 'ACIHWY' }
                    ]}
                    onChange={(event) =>
                      setModule(event.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                  <Grid container justify='flex-end' spacing={2}>
                    <Grid item xs='auto'>
                      <CngButton size='medium' variant='outlined' onClick={masterTemplateFile}>
                        Download template CSV
                      </CngButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <CngFileUpload
                accept={['.csv']}
                maxFiles={1}
                maxSize={6291456}
                files={file}
                onFileSelect={(file) => methods.setValue('file', file)}
                showFormFields={false}
                onDropRejected={(message) => showNotification('error', message)}
                moreActions={[
                  { action: 'remove', name: 'Remove', icon: ['fal', 'trash'] }
                ]}
              />

              {errors?.file && (<Typography color='error' variant='body2'>{errors.file.message}</Typography>)}

              <ErrorMessageSection errorMessages={errorMessages} />
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='flex-end'>
                <Grid item xs='auto'>
                  <CngButton
                    disabled={!file || file.length == 0 || loading}
                    onClick={methods.handleSubmit(handleUploadFile)}
                  >
                    Upload
                  </CngButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
    </Card>
  )
}

export default UploadMasterData
