const UserProfileApiUrls = {
    GET: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/profile/get`,
    POST: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/profile/get-one`,
    UPLOAD_SIGN_FILE: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/profile/uploadSignFile`,
    UPLOAD_LOGO: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/profile/uploadCSLogo`,
    SB_PARTYID_LIST: `${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/profile/getServiceBureauPartyIdByUserName`,
    GET_ALL_CHILD_PARTY_ID_BY_USERNAME: ` ${process.env.REACT_APP_USER_ACI_SHARED_URL}/shared/profile/getAllChildPartyIdByUsername`,
  };
  
  export default UserProfileApiUrls
  