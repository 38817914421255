import { Box, Button, Grid } from "@material-ui/core";
import React, { useContext } from "react";
import { ReTriggerSubmissionTranslationContext } from '../contexts/ReTriggerSubmissionTranslationContext'
import { components} from 'cng-web-lib'

const {
  button: { CngButton }
} = components

function StatusConfigButtonComponent({
  onSearch,
  onSubmitAll,
  onClear,
  formDetailState
}) {

  
  const { getTranslatedText } = useContext(ReTriggerSubmissionTranslationContext)

  return (
    <Box pl={5} pr={5} pt={4} pb={4}>
      <Grid container xs={12} sm={12}>
        <Grid container xs={12} sm={12} justify="center">
          {formDetailState &&
            <Box pr={2}>
              <CngButton
                variant="contained"
                className="button-blue originalText"
                onClick={() => {
                  onSearch();
                }}
              >
                {getTranslatedText('RE_TRIGGER_SUBMISSION', 'searchButton')}
              </CngButton>
            </Box>
          }
          {formDetailState &&
            <Box pr={2}>
              <CngButton
                variant="contained"
                className="button-blue originalText"
                onClick={() => {
                  onSubmitAll();
                }}
              >
                {getTranslatedText('RE_TRIGGER_SUBMISSION', 'submitAllButton')}
              </CngButton>
            </Box>
          }
          <Box pr={2}>
            <CngButton
              variant="contained"
              className="button-blue originalText"
              onClick={() => {
                onClear();
              }}
            >
              {getTranslatedText('RE_TRIGGER_SUBMISSION', 'clearButton')}
            </CngButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default StatusConfigButtonComponent;
