import React from 'react'
import { components } from 'cng-web-lib'
import ManifestApiUrls from '../../../../apiUrls/SbciManifestApiUrls'

const {
  form: {
    field: {
      CngLookupAutocompleteField,
      CngServerSideAutocompleteField
    }
  }
} = components

function SbciManifestTemplateAutoComplete(props) {
  const { lookupProps = {}, ...lookupAutocompleteProps } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps}
      minFilterChars={1}
      debounceInterval={1000}
      openOnFocus={true}
      onMouseDownCapture={(e) => e.stopPropagation()}
      searchableFields={['templateName']}
      lookupPageableProps={{
        customData: { "filters": filters },
        url: ManifestApiUrls.MANIFEST_BASE_GET,
        label: 'templateName',
        value: 'templateName',
        filters: [
          {
            field: 'status',
            operator: 'equal',
            value: 'TP'
          },
          ...filters
        ],
        pageSize: 1000,
        ...otherLookupProps
      }}
    />
  )
}

export default SbciManifestTemplateAutoComplete
