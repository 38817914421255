import  SbciInvoiceApiUrls  from '../../../../apiUrls/SbciInvoiceApiUrls'
import { CodeMaintenanceType } from 'src/constants/nacodemaintenance'
import { components} from 'cng-web-lib'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useServices } from 'cng-web-lib'
const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField,
      CngServerSideAutocompleteField
    },
  },
  CngGridItem,
} = components

/**
 *
 * @visibleName Code maintenance autocomplete
 */
function SbciInvoiceAutoCompleteMasterField(props, codeMaintenanceType) {

  const {createRecord} = useServices()
  const history = useHistory()
  var codeMType = ""

  const {
    lookupProps = {},
    ...lookupAutocompleteProps
  } = props
  const { filters = [], ...otherLookupProps } = lookupProps

  return (
    <CngServerSideAutocompleteField
      {...lookupAutocompleteProps}
      minFilterChars={1}
      debounceInterval={1000}
      openOnFocus={true}
      onMouseDownCapture={(e) => e.stopPropagation()}
      searchableFields={['templateName']}
      lookupPageableProps={{
        customData: { "filters": filters },
        url: SbciInvoiceApiUrls.SEARCH,
        label: 'templateName',
        value: 'templateName',
        filters: [
          {
            field: 'status',
            operator: 'equal',
            value: 'TP'
          },
          ...filters
        ],
        pageSize: 1000,
        ...otherLookupProps
      }}
    />
  )
}

SbciInvoiceAutoCompleteMasterField.propTypes = {
  /** @ignore */
  codeMaintenanceType: PropTypes.oneOf(Object.values(CodeMaintenanceType))
    .isRequired,
  /** @ignore */
  lookupProps: PropTypes.object
}

export default SbciInvoiceAutoCompleteMasterField
