import React ,{useEffect,useState} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import AceOcnManifestKeys from 'src/constants/locale/key/AceOcnManifest'
import { useWatch,useFormContext } from "react-hook-form"
import { Grid ,Typography,InputAdornment,IconButton,Box,Tooltip} from '@material-ui/core'
import { components, DataFlattener, DateTimeFormatter,constants} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import CngSection from '../../../components/cngcomponents/CngSection'
import { NaUSPortAutocompleteField } from 'src/components/na'
import { FileForUserGetUserDetails,FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import { NaAceOcnVesselMasterAutoCompleteField } from 'src/components/na/autocomplete/codemaintenance'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TemplateSelectDialog from '../../../components/aciacehighway/TemplateSelectDialog'
import  AceOcnVesselMasterApiUrls  from 'src/apiUrls/AceOcnVesselMasterApiUrls'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngCountryAutocompleteField,
      CngCodeMasterAutocompleteField,
      CngCheckboxField
    },
  },
  button: { CngIconButton },
  CngGridItem,
} = components

const {
  filter: {  EQUAL,LIKE }
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  scac: "",
  vesselName: "",
  voyageNumber: "",
  vesselCountry: "CA",
  portOfDischarge: "",
  etaDate: "",
  modeOfTransport: "",
  paperlessInbondParticipant: "",
  issuerScac: "",
  modeOfSubmission: "UI",
  status: "",
  saveFlag: false,
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.ACE_OCN_MANIFEST)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { setValue ,watch} = useFormContext();
  const { reset, trigger } = useFormContext();
  const [templateDialog, setTemplateDialog] = useState(false)

  const saveFlag = watch('saveFlag')
  const vesselCountry = watch('vesselCountry')
  const scac = watch('scac')

  useWatch({ name: "fileForUserId" })
  useWatch({ name: "fileForUserLoginId" })
  useWatch({ name: "fileForUserPartyId" })
  useEffect(() => {
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setValue("fileForUserId", fileForUserDetails.fileForUserId)
      setValue("fileForUserLoginId", fileForUserDetails.fileForUserLoginId)
      setValue("fileForUserPartyId", fileForUserDetails.fileForUserPartyId)
    }
  }, []);

  useEffect(() => {
    if(scac){
    setValue("issuerScac", scac)
    }
  }, [scac]);


  const columns = [
    {
      field: 'vesselName',
      title: "Vessel Name"
    },
    {
      field: 'vesselCountry',
      title: "Vessel Country",
    },
    {
      field: 'vesselCode',
      title: "Vessel Code",
    },
    {
      field: 'vesselOwner',
      title: "Vessel Owner",
    }
  ]

  const sortOptions = [
    {
      label: "Vessel Name",
      value: 'vesselName'
    }
  ]

  function handleApplyTemplate(template) {
    if (template) {
      const {
        vesselName,
        vesselCountry,
      } = template
    
      setValue("vesselName", vesselName || '',  { shouldDirty: true })
      setValue("vesselCountry", vesselCountry || '', { shouldDirty: true })

      trigger()
    } else {
      reset()
    }
  }

  function makeTranslatedTextsObject() {
    let aceOcnManifest = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.TITLE
    )
    let scac = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SCAC
    )
    let vesselName = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VESSEL_NAME
    )
    let voyageNumber = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VOYAGE_NUMBER
    )
    let vesselCountry = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.VESSEL_COUNTRY
    )
    let portOfDischarge = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PORT_OF_DISCHARGE
    )
    let etaDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.E_T_A
    )
    let modeOfTransport = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MODE_OF_TRANSPORT
    )
    let paperlessInbondParticipant = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PAPERLESS_INBOND_PARTICIPANT
    )
    let issuerScac = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.ISSUER_SCAC
    )
    let partyId = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.PARTY_ID
    )
    let submittedBy = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMITTED_BY
    )
    let submittedDate = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SUBMITTED_DATE
    )
    let modeOfSubmission = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.MODE_OF_SUBMISSION
    )
    let status = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.STATUS
    )
    let saveFlag = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SAVE_FLAG
    )
    let scacInfoManifest = translate(
      Namespace.ACE_OCN_MANIFEST,
      AceOcnManifestKeys.SCAC_INFO_MANIFEST
    )

    return {
      aceOcnManifest,
      scac,
      vesselName,
      voyageNumber,
      vesselCountry,
      portOfDischarge,
      etaDate,
      modeOfTransport,
      paperlessInbondParticipant,
      issuerScac,
      modeOfSubmission,
      status,
      saveFlag,
      scacInfoManifest
    }
  }

	return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CngSection title={translatedTextsObject.aceOcnManifest}
            subheader={
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography color='textSecondary' variant='caption'>
                  <Typography variant='inherit' color='error'>*</Typography>Mandatory fields</Typography>

                <Tooltip placement='right' title={translatedTextsObject.scacInfoManifest}>
                  <span>
                    <CngIconButton
                      icon={['fal', 'info-circle']}
                      size='small'
                      type='outlined'
                    />
                  </span>
                </Tooltip>
              </Box>
            }>
          <Grid container spacing={2}>
          <Grid item xs={12}>
          <Grid container spacing={1}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scac}>
            <CngTextField
              name="scac"
              label={translatedTextsObject.scac}
              disabled={disabled}
              required
              size='small'
              onChange={(e) => {
                setValue('scac', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('scac', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.etaDate}>
            <CngDateField
              name="etaDate"
              label={translatedTextsObject.etaDate}
              size='small'
              format='YYYY-MM-DD'
              required
              disabled={disabled}
            />
          </CngGridItem>
        <CngGridItem xs={12}>
          <Grid container spacing={1}>
            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap?.saveFlag}>
              <CngCheckboxField
                label={
                  <Typography variant='body2' style={{ fontSize: 12, lineHeight: 1.2 }}>
                    {'Save to Vessel Master'}
                  </Typography>
                }
                name='saveFlag'
                onChange={(e) => setValue('saveFlag', e.target.checked)}
                size='small'
                style={{ marginLeft: 8, marginRight: 8, padding: 8 }}
              />
            </CngGridItem>
            <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap?.vesselMasterDropDown}>
              <NaAceOcnVesselMasterAutoCompleteField
                name='vesselMasterDropDown'
                label="Vessel Master"
                disabled={disabled}
                size='small'
                    fullWidth
                    disableClearable
                    textFieldProps={{
                      InputProps: {
                        customEndAdornment: () => (
                          <InputAdornment position='end' style={{ marginTop: -16 }}>
                            <IconButton onClick={(event) => {
                              event.stopPropagation()
                              setTemplateDialog(true)
                            }}>
                              <Box display='flex' alignItems='center' justifyContent='center' width={16} height={16}>
                                <FontAwesomeIcon icon={['fal', 'money-check-edit']} size='xs' />
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }}
                    forcePopupIcon={false}
                    lookupProps={{
                      filters: [{
                        field: 'partyId',
                        operator: EQUAL,
                        value: FileForUserGetPartyId()
                      }
                    ]
                    }}
                  />
            </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.vesselName}>
            <CngTextField
              name="vesselName"
              label={translatedTextsObject.vesselName}
              size='small'
              required
              disabled={disabled}
              onChange={(e) => {
                setValue('vesselName', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('vesselName', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.modeOfTransport}>
            <CngCodeMasterAutocompleteField
              name="modeOfTransport"
              label={translatedTextsObject.modeOfTransport}
              size='small'
              disabled={false}
              required
              codeType='ACEOCN_MANI_MOT'
            />
          </CngGridItem>
          </Grid>
        </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNumber}>
            <CngTextField
              name="voyageNumber"
              label={translatedTextsObject.voyageNumber}
              size='small'
              required
              disabled={disabled}
              onChange={(e) => {
                setValue('voyageNumber', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('voyageNumber', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselCountry}>
            <CngCountryAutocompleteField
              name="vesselCountry"
              label={translatedTextsObject.vesselCountry}
              disabled={false}
              required
              key={vesselCountry}
              lookupProps={{
                label: (record) => {
                  return `${record.code};${record.descriptionEn}`
                }
              }}
              size='small'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfDischarge}>
            <NaUSPortAutocompleteField
              name="portOfDischarge"
              label={translatedTextsObject.portOfDischarge}
              size='small'
              required
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.paperlessInbondParticipant}>
            <CngCodeMasterAutocompleteField
              name="paperlessInbondParticipant"
              label={translatedTextsObject.paperlessInbondParticipant}
              size='small'
              disabled={false}
              required
              codeType='Y_OR_N'
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.issuerScac}>
            <CngTextField
              name="issuerScac"
              label={translatedTextsObject.issuerScac}
              size='small'
              required
              disabled={disabled}
              onChange={(e) => {
                setValue('issuerScac', e.target.value.toUpperCase(), {
                  shouldValidate: true
                })
              }}
              onBlur={(e) => {
                if (e.target.value !== undefined) {
                  setValue('issuerScac', e.target.value.trim(), {
                    shouldValidate: true
                  })
                }
              }}
            />
          </CngGridItem>
        </Grid>
        </Grid>
      </Grid>
        </CngSection>
      </Grid>
      </Grid>

      <TemplateSelectDialog
        columns={columns}
        defaultSorts={[{ field: 'vesselName', direction: 'ASC' }]}
        fetch={{ url: AceOcnVesselMasterApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        onApplyTemplate={handleApplyTemplate}
        onClose={() => setTemplateDialog(false)}
        open={templateDialog}
        search={{ field: 'vesselName', operator: LIKE }}
        sortOptions={sortOptions}
        title='Template'
        />
    </>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.submittedDate = DateTimeFormatter.toClientDate(
    localData.submittedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.submittedDate = DateTimeFormatter.toServerDate(
    localData.submittedDate
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
