import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'
import { validateYupSchema } from 'formik'
import ReTriggerSubmissionKeys from 'src/constants/locale/key/ReTriggerSubmission'
import moment from 'moment-timezone'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let errLaterFromSubmittedDatetime = translate(
    Namespace.RE_TRIGGER_SUBMISSION,
    ReTriggerSubmissionKeys.ValidationMessage.ERROR_LATER_THAN_FROM_SUBMITTED_DATETIME
  )

  return Yup.object({
    fromSubmittedDatetime: Yup.date(),
    toSubmittedDatetime: Yup.date().when('fromSubmittedDatetime', (fromSubmittedDatetime,validateYupSchema) => fromSubmittedDatetime && validateYupSchema.min(fromSubmittedDatetime,errLaterFromSubmittedDatetime))
  })
}

export default makeValidationSchema
