import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayAbiInbond7512Keys from 'src/constants/locale/key/AceHighwayAbiInbond7512'
import moment from 'moment'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  let minLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MIN_LENGTH
  )

  let maxLengthErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.MAX_LENGTH
  )
  let numericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.NUMERIC
  )
  let alphaNumericErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC
  )
  let alphaNumericSpecialCharErrorMessage = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.ALPHANUMERIC_SPECIAL_CHAR
  )
  let arrivalDateTimeFormat = translate(
    Namespace.ACE_HIGHWAY_ABI_INBOND_7512,
    AceHighwayAbiInbond7512Keys.Validations.INVALID_ARRIVAL_DATE_TIME_FORMAT
  )

  let regexIRS= "^\\d{2}\\-\\d{7}[a-zA-Z0-9]{2}$"
  let regexSSN= "^\\d{3}\\-\\d{2}\\-\\d{4}$"
  let regexCBP= "^\\d{6}\\-\\d{5}$"
  let regexNumeric = "^[0-9]*$"
  let regexAlphaNumeric = "^[a-zA-Z0-9]*$"
  let regexAlphaNumericSpecChar= "^[a-zA-Z0-9 ,+\\-$#@%&()!'`^_:;.?\\[\\]\\{\\}\\/=\"\\|\\\\ ]*$"
  let regexDate= "[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])"
  let regexTime= "(2[0-3]|[01][0-9]):[0-5][0-9]"

  return Yup.object({
    filerCode: Yup.string().nullable(),
    portCode: Yup.string().nullable(),
    actionCode: Yup.string().required(requiredMessage).nullable(),
    entryType: Yup.string().required(requiredMessage).nullable(),
    bondNumber: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(12, maxLengthErrorMessage + " 12").nullable(),
    inbondCarrierCode: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(4, maxLengthErrorMessage + " 4").nullable(),
    destinationPort:  Yup.string().required(requiredMessage).nullable(),
    foreignPort: Yup.string().when('entryType', {
      is: (value)=>value === "63",
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
    inbondValue: Yup.string().required(requiredMessage).min(1, minLengthErrorMessage + " 1").matches(regexNumeric, numericErrorMessage).nullable(),
    inbondCarrierId: Yup.string().max(12, maxLengthErrorMessage + " 12")
    .when('inbondCarrierIdType', {
      is: (inbondCarrierIdType) => (inbondCarrierIdType === 'IRS'),
      then:  Yup.string().required(requiredMessage).nullable().matches(regexIRS,"Incorrect In-bond Carrier ID format: 12-1234567XX")
    })
    .when('inbondCarrierIdType', {
      is: (inbondCarrierIdType) => (inbondCarrierIdType === 'SSN'),
      then:  Yup.string().required(requiredMessage).nullable().matches(regexSSN,"Incorrect In-bond Carrier ID format: 123-45-6789")
    })
    .when('inbondCarrierIdType', {
      is: (inbondCarrierIdType) => (inbondCarrierIdType === 'CBP'),
      then:  Yup.string().required(requiredMessage).nullable().matches(regexCBP,"Incorrect In-bond Carrier ID format: 123456-12345")
    }).nullable(),
    ftzIndicator: Yup.string().nullable(),
    btaFdaIndicator: Yup.string().nullable(),

    conveyanceCarrierCode: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,alphaNumericErrorMessage).max(4, maxLengthErrorMessage + " 4").nullable(),
    mot: Yup.string().required(requiredMessage).nullable(),
    carrierCountry: Yup.string().nullable(),
    conveyanceName: Yup.string().matches(regexAlphaNumericSpecChar,alphaNumericSpecialCharErrorMessage).max(23, maxLengthErrorMessage + " 23").nullable(),
    tripNumber: Yup.string().matches(regexAlphaNumeric,alphaNumericErrorMessage).max(5, maxLengthErrorMessage + " 5").nullable(),
    arrivalPort: Yup.string().required(requiredMessage).nullable(),
    eta: Yup.date().typeError(arrivalDateTimeFormat).when('ftzIndicator', {
      is: (value)=>value === false,
      then: Yup.date().typeError(arrivalDateTimeFormat).required(requiredMessage).nullable(),
    }).nullable(),
    ftzFirmsCode: Yup.string().when('ftzIndicator', {
      is: (value) => value === "true",
      then: Yup.string().required(requiredMessage).nullable(),
    }).nullable(),
  })
}

export default makeValidationSchema
