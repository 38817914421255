import { constants } from 'cng-web-lib'

const {
  locale: { Namespace: CngNamespace }
} = constants

const Namespace = {
  ...CngNamespace,
  PRODUCT_MASTER: 'product-master',
  PARTY_MASTER: 'party-master',
  USER_PROFILE: 'user-profile',
  ARRIVALMESSAGE: 'arrivalmessage',
  ACI_AIR: 'aci-air',
  A6: 'aciocean',
  CONVEYANCE: 'conveyance',
  A6A: 'a6a',
  TEST_ARRIVAL_REPORT: 'test-arrival-report',
  DOC_GEN_PURCHASE_ORDER: 'doc-gen-purchase-order',
  STATUS_MESSAGE: 'status-message',
  H2H_SUB_FAILS: 'h2h-sub-fails',
  INVOICE: 'invoice',
  FILE_USER: 'file-user',
  FLIGHT_MATRIX: 'flight-matrix',
  VALIDATION_ERROR_LOG: 'validation-error-log',
  H_S_CODE: 'h-s-code',
  DBADMIN_ACI_AIR: 'dbadmin-aciair',
  SBCI_INVOICE: 'sbci-invoice',
  SBCI_PRODUCT_MASTER: 'sbci-product-master',
  SBCI_MANIFEST: 'sbci-manifest',
  SBCI_FILE_UPLOAD_HISTORY: 'sbci-file-upload-history',
  EQUIPMEMENT_MASTER: 'equipment-master',
  ACIHWY_MANIFEST: 'acihighway-manifest',
  CONVEYANCE_MASTER: 'conveyance-master',
  ACI_HIGHWAY_STAT_QRY: 'aci-status-query',
  CREW_MASTER: 'crew-master',
  US_PRODUCT_MASTER: 'us-product-master',
  ACI_HIGHWAY_CARGO: 'aci-hw-cargo',
  ACI_HIGHWAY_RESPONSE: 'aci-hw-response',
  ACE_HIGHWAY_MANIFEST: 'ace-highway-manifest',
  ACE_HIGHWAY_SHIPMENT: 'ace-highway-shipment',
  ACE_HIGHWAY_ABI_INBOND_7512: 'ace-highway-abi-inbond-7512',
  ACE_HIGHWAY_ABI_INBOND_EVENT: 'ace-highway-abi-inbond-event',
  EHBL_HOUSE_BILL: 'ehbl-house-bill',
  EHBL_CLOSE_MSG: 'ehbl-close-msg',
  ACE_HIGHWAY_RESPONSE: 'ace-highway-response',
  USER_NAME_PASSWORD: 'username-password',
  FILE_FOR_USER: 'file-for-user',
  ACE_HIGHWAY_INBOND_ARRIVAL: 'aceHwyInbondArrival',
  ARRIVAL_CERT: 'arrival-cert',
  ARRIVAL_CERT_RESPONSE: 'arrival-cert-response',
  ACI_HW_RELEASE_NOTICE: 'aci-hw-release-notice',
  COMMUNICATION_UTIL: 'communication-util',
  COMMUNICATION_UTIL: 'communication-util',
  DB_ADMIN_STATUS_CONFIG: 'db-admin-status-config',
  NBCI_USCA_PARTY_MASTER: 'usca-party-master',
  US_DECLARATIONS: 'us-declarations',
  FAX_TRACKER: 'fax-tracker',
  N_B_C_I_CANADA_INVOICE: 'n-b-c-i-canada-invoice',
  NBCI_US_INVOICE: 'nbci-us-invoice',
  BILLING_CONFIG: 'billing-config',
  HOME_PAGE : 'home-page',
  ANNOUNCEMENT: 'announcement',
  COMMON_VALIDATION_MESSAGE: 'common-validation-message',
  EHBL_MANIFEST: 'ehbl-manifest',
  EHBL_RESPONSE: 'ehbl-response',
  ACE_ISF: 'ace-isf',
  ACE_OCN_MANIFEST: 'ace-ocn-manifest',
  ACE_OCN_BILLOFLADING: 'ace-ocn-billoflading',
  ACE_OCN_VESSEL_MASTER: 'ace-ocn-vessel-master',
  ACE_OCN_VESSEL_DEPARTURE: 'ace-ocn-vessel-departure',
  ACE_OCN_VESSEL_DEPARTURE_RESPONSE: 'ace-ocn-vessel-departure-response',
  ACE_OCN_VESSEL_ARRIVAL: 'ace-ocn-vessel-arrival',
  ACE_OCN_VESSEL_ARRIVAL_RESPONSE: 'ace-ocn-vessel-arrival-response',
  NA_CODE_MASTER: 'na-code-master',
  PARTY_CONFIG: 'party-config',
  AES_SHIPMENT: 'aes-shipment',
  AES_PARTY_SCREENING: 'aes-party-screening',
  PARTY_CONFIG: 'party-config',
  FDAPN: 'fdapn',
  FDAPN_PRODUCT_MASTER: 'fdapn-product-master',
  RE_TRIGGER_SUBMISSION: 're-trigger-submission'
}

export default Namespace
