const AceHighwayManifestKeys = {
  TITLE: "title",
  SHIPMENT_TITLE: "shipmentTitle",
  RESPONSE_TITLE: "responseTitle",
  ACTIVITYLOG_TITLE: "activityLogTitle",
  TRUCK_CONVEYANCE_TITLE: "Truck [Conveyance]",
  ADDITIONAL_LICENSE_PLATE_TITLE: "Additional License Plate Info",
  ADDITIONAL_SEAL_NO_TITLE: "Additional Seal No.",
  ADDITIONAL_IIT_TITLE: "Additional IIT Info",
  ID: "id",
  HEADER_ID: "headerId",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  VERSION: "version",
  PARTY_ID: "partyId",
  CARRIER_SCAC: "carrierScac",
  TRIP_NO: "tripNo",
  DRIVER: "driver",
  TRUCK: "truck",
  TRAILER: "trailer",
  ARRIVAL_DATE: "arrivalDate",
  ARRIVAL_DATE_ONLY: "arrivalDateOnly",
  ARRIVAL_TIME: "arrivalTime",
  TRANSIT_INDICATOR: "transitIndicator",
  FIRST_EXPECTED_PORT: "firstExpectedPort",
  SHIPMENT_COUNT: "shipmentCount",
  MSG_FUNCTION: "msgFunction",
  AMENDMENT_CODE: "amendmentCode",
  STATUS: "status",
  REVIEW_STATUS: "reviewStatus",
  PAXLST_PREV_STATUS: "paxlstPrevStatus",
  PAXLST_STATUS: "paxlstStatus",
  RESPONSE_DATE: "responseDate",
  TEMPLATE_FLAG: "templateFlag",
  TEMPLATE_NAME: "templateName",
  FETCH_TRUCK: "fetchTruck",
  SAVE_TRUCK_FLAG: "saveTruckFlag",
  TRUCK_ID: "truckId",
  ACE_ID: "aceId",
  ACE_FLAG: "aceFlag",
  CONVEYANCE_ID: "conveyanceId",
  CONVEYANCE_NO: "conveyanceNo",
  CONVEYANCE_TYPE: "conveyanceType",
  CONVEYANCE_REMARK: "conveyanceRemark",
  PAXLST_REMARK: "paxlstRemark",
  EMAIL: "email",
  FAX: "fax",
  VIN: "vin",
  DOT_NO: "dotNo",
  TRANSPONDER_ID: "transponderId",
  LICENSE_PLATE_NO1: "licensePlateNo1",
  LICENSE_PLATE_COUNTRY1: "licensePlateCountry1",
  LICENSE_PLATE_STATE1: "licensePlateState1",
  LICENSE_PLATE_NO2: "licensePlateNo2",
  LICENSE_PLATE_COUNTRY2: "licensePlateCountry2",
  LICENSE_PLATE_STATE2: "licensePlateState2",
  LICENSE_PLATE_NO3: "licensePlateNo3",
  LICENSE_PLATE_COUNTRY3: "licensePlateCountry3",
  LICENSE_PLATE_STATE3: "licensePlateState3",
  LICENSE_PLATE_NO4: "licensePlateNo4",
  LICENSE_PLATE_COUNTRY4: "licensePlateCountry4",
  LICENSE_PLATE_STATE4: "licensePlateState4",
  IIT_CODE1: "iitCode1",
  IIT_CODE2: "iitCode2",
  IIT_CODE3: "iitCode3",
  IIT_CODE4: "iitCode4",
  SEAL_NO1: "sealNo1",
  SEAL_NO2: "sealNo2",
  SEAL_NO3: "sealNo3",
  SEAL_NO4: "sealNo4",
  SEAL_NO5: "sealNo5",
  SEAL_NO6: "sealNo6",
  SEAL_NO7: "sealNo7",
  SEAL_NO8: "sealNo8",
  SEAL_NO9: "sealNo9",
  INTEGRATEION: "integration",
  ADDITIONAL_LICENSE_PLATE_HIDE_TEXT: "additionalLicensePlateHideText",
  ADDITIONAL_LICENSE_PLATE_SHOW_TEXT: "additionalLicensePlateShowText",
  ADDITIONAL_LICENSE_PLATE_FOOTER_TEXT: "additionalLicensePlateFooterText",
  ADDITIONAL_SEAL_NO_HIDE_TEXT: "additionalSealNoHideText",
  ADDITIONAL_SEAL_NO_SHOW_TEXT: "additionalSealNoShowText",
  ADDITIONAL_SEAL_NO_FOOTER_TEXT: "additionalSealNoFooterText",
  ADDITIONAL_IIT_HIDE_TEXT: "additionalIITHideText",
  ADDITIONAL_IIT_SHOW_TEXT: "additionalIITShowText",
  ADDITIONAL_IIT_FOOTER_TEXT: "additionalIITFooterText",
  SAVE_BUTTON: "saveButton",
  UPDATE_BUTTON: "updateButton",
  SUBMIT_BUTTON: "submitButton",
  ATTACH_BUTTON: "attachButton",
  UPLOAD_BUTTON: "uploadButton",
  UPLOAD_SHIPMENT_BUTTON: "uploadShipmentButton",
  SUBMIT_PAXLST_BUTTON: "submitPAXLSTButton",
  ACI_HIGHWAY_ADD: "aciHighwayAdd",
  EDIT_SENT_RECORDS: "editSentRecords",
  EDIT_BUTTON: "editButton",
  DELETE_BUTTON: "deleteButton",
  DELETE_DRAFT_RECORDS: "deleteDraftRecords",
  CANCEL_BUTTON: "cancelButton",
  CANCEL_MULTIPLE_RECORDS:"cancelMultipleRecords",
  CLONE_BUTTON: "cloneButton",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ONEREC_ALERT: "submitOneRecordAlert",
  PARTY_INFO_DETAIL_TITLE: "partyInfoDetail",
  PARTY_INFO_ADDRESS_TITLE: "partyInfoAddress",
  PAXLST_INFO_ADDRESS_TITLE: "paxlstInfoAddress",
  PARTY_INFO_LICENSE_TITLE: "partyInfoLicense",
  PARTY_INFO_DOCUMENT_TITLE: "partyInfoDocument",
  SAVE_EQUIPMENT_FLAG: "saveEquipmentFlag",
  FETCH_EQUIPMENT: "fetchEquipment",
  FETCH_CREW: "fetchCrew",
  SAVE_CREW_FLAG: "saveCrewFlag",
  CREATED_DATE_TIME: "createdDateTime",
  LOGIN_USER_ID: "loginUserId",
  FUNCTION_CODE: "functionCode",
  AUDIT_DESC: "auditDescription",
  DELETE_DRAFT_RECORDS: "deleteDraftRecords",
  MANIFEST_HELPER_1: "manifestHelper1",
  MANIFEST_HELPER_2: "manifestHelper2",
  CONVEYANCE_HELPER_1: "conveyanceHelper1",
  CONVEYANCE_HELPER_2: "conveyanceHelper2",
  CONVEYANCE_HELPER_3: "conveyanceHelper3",
  CONVEYANCE_HELPER_4: "conveyanceHelper4",
  EQUIPMENT_HELPER_1: "equipmentHelper1",
  EQUIPMENT_HELPER_2: "equipmentHelper2",
  INSURANCE_HELPER_1: "insuranceHelper1",
  PARTYINFO_HELPER_1: "partyInfoHelper1",
  PARTYINFO_HELPER_2: "partyInfoHelper2",
  PARTYINFO_HELPER_3: "partyInfoHelper3",
  PAXLST_HELPER_1: "paxlstHelper1",
  PAXLST_DIALOG_TITLE: "paxlstDialogTitle",
  PAXLST_DIALOG_INFO: "paxlstDialogInfo",
  SHIPMENT_HELPER_1: "shipmentHelper1",
  SHIPMENT_HELPER_2: "shipmentHelper2",
  SHIPMENT_HELPER_3: "shipmentHelper3",
  TEMPLATE_HELPER_1: "manifestTemplateHelper1",
  SAVE_INSURANCE_FLAG: "saveInsuranceFlag",
  PRINT_MANIFEST_SHIPMENT:"printManifestShipment",
  PRINT_COVERSHEET:"printCoverSheet",
  EMAIL_COVERSHEET:"emailCoverSheet",
  EMAIL:"emailTitle",
  EMAIL_SUBJECT:"emailSubject",
  EMAIL_MESSAGE:"emailMessage",
  FAX_COVERSHEET:"faxCoverSheet",
  FAX_HELP_TEXT:"faxHelpText",
  FAX_REMARK:"faxRemark",
  // FAX:"faxCoverSheet",
  // FAX:"faxTitle",
  FAX_SUBJECT:"faxSubject",
  FAX_MESSAGE:"faxMessage",
  SEND_BUTTON: "sendButton",
  PRINT_COVERSHEET_SUMMARY:"printCoverSheetSummary",
  PRINT_7533: "print7533",
  PRINT_7521: "print7521",
  PRINT_7512: "print7512",
  PRINT_MULTIPLE_RECORDS:"printMultipleRecords",
  NOTE_FLAG:"noteFlag",
  NOTE_CONTENT:"noteContent",
  UPDATE_SHIPMENT_INFO:"updateShipmentInfo",
  AUTO_SUBMIT_PAXLST: "autoSubmitPaxlst",
  AUTO_CONFIRM_MANIFEST: "autoConfirmManifest",
  EquipmentInfo: {
    TITLE: "equipmentInfo.title",
    ID: "equipmentInfo.id",
    HEADER_ID: "equipmentInfo.headerId",
    CREATED_BY: "equipmentInfo.createdBy",
    CREATED_DATE: "equipmentInfo.createdDate",
    UPDATED_BY: "equipmentInfo.updatedBy",
    UPDATED_DATE: "equipmentInfo.updatedDate",
    SUBMITTED_BY: "equipmentInfo.submittedBy",
    SUBMITTED_DATE: "equipmentInfo.submittedDate",
    VERSION: "equipmentInfo.version",
    EQUIPMENT_ID: "equipmentInfo.equipmentId",
    EQUIPMENT_ACE_ID: "equipmentInfo.equipmentAceId",
    EQUIPMENT_TYPE: "equipmentInfo.equipmentType",
    EQUIPMENT_NO: "equipmentInfo.equipmentNo",
    LICENSE_PLATE_NO1: "equipmentInfo.licensePlateNo1",
    LICENSE_PLATE_COUNTRY1: "equipmentInfo.licensePlateCountry1",
    LICENSE_PLATE_STATE1: "equipmentInfo.licensePlateState1",
    LICENSE_PLATE_NO2: "equipmentInfo.licensePlateNo2",
    LICENSE_PLATE_COUNTRY2: "equipmentInfo.licensePlateCountry2",
    LICENSE_PLATE_STATE2: "equipmentInfo.licensePlateState2",
    LICENSE_PLATE_NO3: "equipmentInfo.licensePlateNo3",
    LICENSE_PLATE_COUNTRY3: "equipmentInfo.licensePlateCountry3",
    LICENSE_PLATE_STATE3: "equipmentInfo.licensePlateState3",
    LICENSE_PLATE_NO4: "equipmentInfo.licensePlateNo4",
    LICENSE_PLATE_COUNTRY4: "equipmentInfo.licensePlateCountry4",
    LICENSE_PLATE_STATE4: "equipmentInfo.licensePlateState4",
    IIT_CODE1: "equipmentInfo.iitCode1",
    IIT_CODE2: "equipmentInfo.iitCode2",
    IIT_CODE3: "equipmentInfo.iitCode3",
    IIT_CODE4: "equipmentInfo.iitCode4",
    SEAL_NO1: "equipmentInfo.sealNo1",
    SEAL_NO2: "equipmentInfo.sealNo2",
    SEAL_NO3: "equipmentInfo.sealNo3",
    SEAL_NO4: "equipmentInfo.sealNo4",
    SEAL_NO5: "equipmentInfo.sealNo5",
    SEAL_NO6: "equipmentInfo.sealNo6",
    SEAL_NO7: "equipmentInfo.sealNo7",
    SEAL_NO8: "equipmentInfo.sealNo8",
    SEAL_NO9: "equipmentInfo.sealNo9",
    ACE_FLAG: "equipmentInfo.aceFlag"
  },
  InsuranceInfo: {
    TITLE: "insuranceInfo.title",
    ID: "insuranceInfo.id",
    HEADER_ID: "insuranceInfo.headerId",
    CREATED_BY: "insuranceInfo.createdBy",
    CREATED_DATE: "insuranceInfo.createdDate",
    UPDATED_BY: "insuranceInfo.updatedBy",
    UPDATED_DATE: "insuranceInfo.updatedDate",
    SUBMITTED_BY: "insuranceInfo.submittedBy",
    SUBMITTED_DATE: "insuranceInfo.submittedDate",
    VERSION: "insuranceInfo.version",
    INSURANCE_COMPANY: "insuranceInfo.insuranceCompany",
    INSURANCE_POLICY_NO: "insuranceInfo.insurancePolicyNo",
    LIABILITY_AMOUNT: "insuranceInfo.liabilityAmount",
    YEAR_OF_ISSUANCE: "insuranceInfo.yearOfIssuance"
  },
  PartyInfo: {
    TITLE: "partyInfo.title",
    ID: "partyInfo.id",
    MANIFEST_HEADER_ID: "partyInfo.manifestHeaderId",
    SHIPMENT_HEADER_ID: "partyInfo.shipmentHeaderId",
    CREATED_BY: "partyInfo.createdBy",
    CREATED_DATE: "partyInfo.createdDate",
    UPDATED_BY: "partyInfo.updatedBy",
    UPDATED_DATE: "partyInfo.updatedDate",
    SUBMITTED_BY: "partyInfo.submittedBy",
    SUBMITTED_DATE: "partyInfo.submittedDate",
    VERSION: "partyInfo.version",
    DOB: "partyInfo.dob",
    NATIONALITY: "partyInfo.nationality",
    GENDER: "partyInfo.gender",
    CREW_TYPE: "partyInfo.crewType",
    PARTY_TYPE:"partyInfo.partyType",
    PARTY_IDENTIFIER: "partyInfo.partyIdentifier",
    PARTY_IDENTIFIER_CODE: "partyInfo.partyIdentifierCode",
    PARTY_IDENTIFIER_VALUE: "partyInfo.partyIdentifierValue",
    NAME1: "partyInfo.name1",
    NAME2: "partyInfo.name2",
    PARTY_DROP_DOWN: "partyInfo.partyDropDown",
    NAME: "partyInfo.name",
    ADDRESS1: "partyInfo.address1",
    ADDRESS2: "partyInfo.address2",
    ADDRESS3: "partyInfo.address3",
    CITY_NAME: "partyInfo.cityName",
    POSTAL_CODE: "partyInfo.postalCode",
    COUNTRY: "partyInfo.country",
    STATE: "partyInfo.state",
    TEL_NO: "partyInfo.telNo",
    EMAIL: "partyInfo.email",
    CTPAT_IND: "partyInfo.ctpatInd",
    HAZMAT_ENDORSEMENT: "partyInfo.hazmatEndorsement",
    LICENSE_NO: "partyInfo.licenseNo",
    LICENSE_COUNTRY: "partyInfo.licenseCountry",
    LICENSE_STATE: "partyInfo.licenseState",
    CDL_NO: "partyInfo.cdlNo",
    CDL_COUNTRY: "partyInfo.cdlCountry",
    CDL_STATE: "partyInfo.cdlState",
    ROUTING_CODE: "partyInfo.routingCode",
    BROKER_DOWNLOAD_REF_NO: "partyInfo.brokerDownloadRefNo",
    PORT_OF_LOADING: "partyInfo.portOfLoading",
    FIRST_NAME: "partyInfo.firstName",
    MIDDLE_NAME: "partyInfo.middleName",
    LAST_NAME: "partyInfo.lastName",
    ACE_ID_FLAG: "partyInfo.aceIdFlag",
    ACE_ID: "partyInfo.aceId",
    FAST_CARD: "partyInfo.fastCard",
    CREW_ID: "partyInfo.crewId",
    PORT_CODE: "partyInfo.portCode",
    FAST_CARD_FLAG: "partyInfo.fastCardFlag",
    SAVE_FLAG:"partyInfo.saveFlag"
  },
  StatusNotification: {
    TITLE: "statusNotification.title",
    NAME: "statusNotification.name",
    SN_TYPE: "statusNotification.snType",
    SHIPMENT_CONTROL_NUM: "statusNotification.shipmentControlNum",
    EMAIL: "statusNotification.email",
    MOBILE_NUM: "statusNotification.mobileNum",
    FAX_NUM: "statusNotification.faxNum"
  },
  InternationalDoc: {
    TITLE: "internationalDoc.title",
    DOC_TYPE: "internationalDoc.docType",
    DOC_NO: "internationalDoc.docNo",
    COUNTRY: "internationalDoc.docCountry",
    STATE: "internationalDoc.docState",
    STATUS: "internationalDoc.docStatus"
  },
  Validations:{
    CARRIER_CODE_LENGTH: "validations.carrierCodeLength",
    CARRIER_CODE_REGX: "validations.carrierCodeRegx",
    TRIP_NUM_LENGTH: "validations.tripNumLength",
    TRIP_NUM_PREFIX: "validations.tripNumPrefix",
    EXP_ARRIVAL_DATE_TIME: "validations.expArrivalDateValidation",
    ALPHA_NUMERIC_REGX:"validations.alphaNumericRegx",
    ALPHA_NUMERIC_SPACE_REGX: "validations.alphaNumericSpaceRegx",
    NUMERIC_REGX: "validations.numericRegx",
    EMAIL_MAX_LENGTH: "validations.emailMaxLength",
    EMAIL_VALID:"validations.emailValid",
    FAX_VALID:"validations.faxValid",
    MAX_LENGTH:"validations.maxLength11",
    MSGFUN_FOR_AC:"validations.msgfunForAC",
    MSGFUN_FOR_DR:"validations.msgfunForDR",
    MSGFUN_FOR_CL:"validations.msgfunForCL",
    MSGFUN_FOR_MR:"validations.msgfunForMR",
    MSGFUN_FOR_RJ:"validations.msgfunForRJ",
    MSGFUN_FOR_CR:"validations.msgfunForCR",
    MSGFUN_FOR_NEW_REC:"validations.msgfunForNewRec",
    ENTER_VALID_INPUT:"validations.enterValidInput",
    NON_FAST_CARD_USER:"validations.nonFastcardUser",
    ALPHA_NUMERIC_WITH_SPLCHARS:"validations.aplhaNumericWithSplChars",
    ALPHANUMERIC_SPECIAL_CHAR: "validations.alphaNumericSpecialChar",
    ALPHANUMERIC_DASH: "validations.alphaNumericWithDash",
    MAX_LENGTH_255:"validations.maxLength255",
    MAX_LENGTH_11:"validations.maxLength11",
    MAX_LENGTH_12:"validations.maxLength12",
    MAX_LENGTH_15:"validations.maxLength15",
    MAX_LENGTH_19:"validations.maxLength19",
    MAX_LENGTH_23:"validations.maxLength23",
    MAX_LENGTH_25:"validations.maxLength25",
    MAX_LENGTH_30:"validations.maxLength30",
    MAX_LENGTH_35:"validations.maxLength35",
    MAX_LENGTH_40:"validations.maxLength40",
    MAX_LENGTH_50:"validations.maxLength50",
    MAX_LENGTH_254:"validations.maxLength254",
    MAX_LENGTH_100:"validations.maxLength100",
    MAX_LENGTH_10:"validations.maxLength10",
    MAX_LENGTH_17:"validations.maxLength17",
    MAX_LENGTH_18:"validations.maxLength18",
    MAX_LENGTH_9:"validations.maxLength9",
    MAX_LENGTH_60:"validations.maxLength60",
    MIN_LENGTH_10:"validations.minLength10",
    TEMPLATE_NAME_REQUIRE:"validations.templateNameRequire",
    ACE_ID_REQUIRED:"validations.aceIdRequire",
    VIN_REQUIRED:"validations.vinRequire",
    EMAIL_FORMAT:"validations.emailFormat",
    MIN_DRIVER_REQUIRED:"validations.minDriverRequired",
    FAST_CARD_REQUIRE:"validations.fastCardRequired",
    ACE_ID_REQUIRE:"validations.aceIdRequired",
    CITY_NAME_REQUIRE:"validations.cityNameRequired",
    POSTAL_CODE_REQUIRE:"validations.postalCodeRequired",
    STATE_REQUIRE:"validations.stateRequired",
    ADDRESS_1_REQUIRE:"validations.address1Required",
    TRUCK_ID_REQUIRED:"validations.truckIdRequired",
    INVALID_ARRIVAL_DATE_TIME_FORMAT:"validations.arrivalDateTimeFormat",
  },
  TRIP_SECTION_HELPER: "tripSectionHelper",
  TRUCK_SECTION_HELPER: "truckSectionHelper",
  EQUIPMENT_SECTION_HELPER: "equipmentSectionHelper",
  INSURANCE_SECTION_HELPER: "insuranceSectionHelper",
  CREW_SECTION_HELPER: "crewSectionHelper",
  PAXLST_SECTION_HELPER: "paxLstSectionHelper"
}

export default AceHighwayManifestKeys
