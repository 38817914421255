const ArrivalmessageKeys = {
  TITLE: "title",
  CARRIERCODE: "carriercode",
  CONVEYANCEREFERENCENUMBER: "conveyancereferencenumber",
  ARRIVALDATETIME: "arrivaldatetime",
  ARRIVAL_DATE: "arrivalDate",
  ARRIVAL_TIME: "arrivalTime",
  PORTOFARRIVAL: "portofarrival",
  SCHEDULED_SUBMISSION_FLAG: "scheduledSubmissionFlag",
  SCHEDULE_DATE_AND_TIME: "scheduleDateAndTime",
  SCHEDULE_DATE: "scheduleDate",
  SCHEDULE_TIME: "scheduleTime",
  AUTO_SUBMIT_TIME_ZONE: "autoSubmitTimeZone",
  AUTO_SEND_HELPER_TEXT :"autoSendHelperText",
  CREATIONDATETIME: "creationdatetime",
  STATUS: "status",
  MODULE: "module",
  TEMPLATE_FLAG: "templateFlag",
  TEMPLATE_NAME:"templateName",
  CANCEL_BUTTON: "cancelButton",
  SUBMIT_BUTTON: "submitButton",
  SAVE_BUTTON: "saveButton",
  SEND_BUTTON: "sendButton",
  EDIT_BUTTON: "editButton",
  VIEW_BUTTON: "viewButton",
  CLONE_BUTTON: "cloneButton",
  DELETE_BUTTON: "deleteButton",
  EDIT_MULTIPLE_RECORDS: "editMultipleRecords",
  EDIT_SENT_RECORDS: "editSentRecord",
  FUNCTIONAL_ACK_RECORD: "functionalAckRecord",
  VIEW_MULTIPLE_RECORDS: "viewMultipleRecords",
  CLONE_MULTIPLE_RECORDS: "cloneMultipleRecords",
  DELETE_MULTIPLE_RECORDS: "deleteMultipleRecords",
  SELECT_ACTION: "selectAction",
  SUBMIT_SENT_RECORD: "submitSentRecord",
  SUBMIT_SUCCESS_MSG: "submitSuccessMsg",
  SUBMIT_ERROR_MSG: "submitErrorMsg",
  SUBMIT_RECORD_DIALOG_CONTENT: "submitRecordDialogContent",
  SUBMIT_RECORD: "submitRecord",
  CONFIRMED_RECORD: "confirmedRecord",
  ERROR_CODE: "errorCode",
  CSAARRIVAL : "csaArrival",
  BUSINESSNUMBER : "businessNumber",
  MESSAGEFUNCTION : "messageFuntion",
  INBONDDESTINATIONOFFICECODE : "inbondDestinationOfficeCode",
  WAREHOUSECODE : "wareHouseCode",
  SUBMISSION_DATE_TIME: "submissionDateTime",
  CCN: "ccn",
  CREATED_BY_LOGIN_ID: "createdByLoginId",
  ArrivalMessageSNDTOs: {
    TITLE: "arrivalMessageSNDTOs.title",
    NAME: "arrivalMessageSNDTOs.name",
    EMAIL: "arrivalMessageSNDTOs.email",
    PHNO: "arrivalMessageSNDTOs.phno",
    SNTYP: "arrivalMessageSNDTOs.snTyp"
  },
  ArrivalResponse:{
    TITLE: "arrivalResponse.title"
  }
}

export default ArrivalmessageKeys
